import { useEffect, useState } from "react"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import GroupHomeNotices from "../../../Components/SharedComponents/GroupData/GroupHomeNotices"
import GroupHomeNewsLetters from "../../../Components/SharedComponents/GroupData/GroupHomeNewsLetters"
import '../UserModalCSS.css'


function UserHomeModal() {

	const [currentlyShowing, setCurrentlyShowing] = useState<'Notice' | 'NewsLetters'>('Notice')
	const { isFirstUpdate, updateNotices, updateNoticesForSpecificSchool, handleLoadingInfo, handleAdminEnter } 
		= useUserModalContext()
	const { setCurrentModal, schoolOfUser, updateGroupsOfUser, user } = useGeneralContext()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		if (!user) {
			return
		}
		if (user.admin === 'SuperAdmin') {
			console.log(schoolOfUser)
			handleAdminEnter(schoolOfUser, schoolOfUser?.SK)
			return
		}
		if (isFirstUpdate && user.admin !== 'SuperAdmin') {
			handleLoadingInfo(setIsLoading)
		} else {
			updateNotices()
		}
	}, [])

	useEffect(() => {
		if (!schoolOfUser || !user) {
			return
		}
		if (isFirstUpdate || user.admin !== 'SuperAdmin') {
			return
		}
		setIsLoading(true)
		updateNoticesForSpecificSchool(schoolOfUser, setIsLoading)
		updateGroupsOfUser(schoolOfUser.SK)
	}, [schoolOfUser])

	const handleCreateButton = () => {
		if (currentlyShowing === 'Notice') {
			setCurrentModal('CreateNotice')
		} else {
			setCurrentModal('CreateNewsLetter')
		}
	}

	return (
		<>
			<div className="HomeModalSuperiorDiv BottomLine">
				<div className="HomeModalButtonDiv">
					<button
						type='button'
						className={currentlyShowing === 'Notice' ? 'HomeModalSelectedType' : 'HomeModalUnselectedType'}
						onClick={() => setCurrentlyShowing('Notice')}
					>
						Avisos
					</button>
					<button
						type='button'
						className={currentlyShowing === 'NewsLetters' ? 'HomeModalSelectedType' : 'HomeModalUnselectedType'}
						onClick={() => setCurrentlyShowing('NewsLetters')}
					>
						Boletin
					</button>
				</div>
				<div className="HomeModalSuperiorInteriorDiv">
					<button
						type='button'
						onClick={handleCreateButton}
					>
						{currentlyShowing === 'Notice' ? 'Nuevo Aviso' : 'Nueva nota'}
					</button>
				</div>
			</div>
			{isLoading ? (
				<h1>Cargando...</h1>
			) : (
				currentlyShowing === 'Notice' ? (
					<GroupHomeNotices />
				) : (
					<GroupHomeNewsLetters />
				)
			)}
			
		</>
	)
}

export default UserHomeModal