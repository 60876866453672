import React, { useState } from "react";
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { useAdminModalContext } from "../../../Contexts/AdminModalContext/AdminModalProvider";
import { BodyCreate, SchoolInterface } from "../../../Interfaces/Entities";
import { DefaultSchool } from "../../../Interfaces/DefaultEntities";
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers";
import { FetchPostFunction } from "../../../Helpers/FetchHelpers";
import defautlSchoolImage from '../../../Assets/BasicSchoolLogo.png'
import '../AdminCSS.css'


function SchoolCreateModal() {

	const { adminSchools, setAdminSchools } = useAdminModalContext()
	const { setCurrentModal } = useGeneralContext()
	const [formData, setFormData] = useState<SchoolInterface>(DefaultSchool)
	const [newLogo, setNewLogo] = useState<File | undefined>(undefined)
	const [logoPreview, setLogoPreview] = useState<string | undefined>(undefined);
	const [isEditingLogo, setIsEditingLogo] = useState<boolean>(false)

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};
	
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const objectUrl = URL.createObjectURL(file);
			setNewLogo(file);
			setLogoPreview(objectUrl);
			setIsEditingLogo(false);
		}
	};
	
	const handleSubmitForm = async () => {
		if (!isFormComplete()) {
			alert('Debe completar todos los valores para crear un colegio válido');
			return;
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/schools`;
			const body: BodyCreate = {
				school_id: formData.SK,
				attrs: {
					name: formData.name,
					address: formData.address,
				},
			};
			if (newLogo) {
				const filePromises = await PrepareFileToSend(newLogo);
				body.files = [filePromises];
			}
			await FetchPostFunction(url, body)
			updateSchoolList();
			alert('Escuela creada correctamente, recuerde actualizar los colegios para ver la imagen seleccionada');
			setCurrentModal('SchoolList');
		} catch (error) {
			alert('Hubo un error al crear el colegio');
		}
	};
	

	const updateSchoolList = () => {
		setAdminSchools([...adminSchools, formData])
	}

	const isFormComplete = (): boolean => {
		return (
			formData.name.trim() !== "" &&
			formData.address.trim() !== "" &&
			formData.SK.trim() !== ""
		)
	}

	return (
		<div className="CreateSchoolMainInternalDiv">
			<div className='CreateSchoolImageDiv'>
				{isEditingLogo ? (
					<>
						<input
							type="file"
							accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.txt,.HEIF"
							onChange={handleFileChange}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
						<button type='button' onClick={() => setIsEditingLogo(false)}>
							Cancelar
						</button>
					</>
				) : (
					newLogo ? (
						<>
							<img src={logoPreview} alt='Logo por defecto' onClick={() => setIsEditingLogo(true)} />
							<button type='button' onClick={() => setIsEditingLogo(true)}>Cambiar logo</button>
						</>
					) : (
						<>
							<img src={defautlSchoolImage} alt='Logo por defecto' onClick={() => setIsEditingLogo(true)} />
							<button type='button' onClick={() => setIsEditingLogo(true)}>Cambiar logo</button>
						</>
					)
				)}
			</div>
			<div className="CreateSchoolDoubleInputDiv">
				<div className="CreateSchoolInputLabel">
					<label>Nombre:</label>
					<input
						type='text'
						value={formData.name}
						name="name"
						onChange={handleInputChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</div>
				<div className="CreateSchoolInputLabel">
					<label>Dirección:</label>
					<input
						type='text'
						name="address"
						value={formData.address}
						onChange={handleInputChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</div>
			</div>
			<div className="CreateSchoolInputLabel">
				<label>Codigo:</label>
				<input
					type='text'
					name="SK"
					value={formData.SK}
					onChange={handleInputChange}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</div>
			<div className="CreateSchoolButtonDiv">
				<button type='button' onClick={() => handleSubmitForm()}>
					Crear Colegio
				</button>
				<button type='button' onClick={() => setCurrentModal('SchoolList')}>
					Cancelar
				</button>
			</div>
		</div>

	)
}

export default SchoolCreateModal