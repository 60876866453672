import React, { useEffect, useRef, useState } from "react";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { SearchUserAdminInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces";
import { UserInterface } from "../../../Interfaces/Entities";
import { FilterUserForSearch } from "../../../Helpers/FilterFuntions";

function SearchUserAdmin( {groupUsers, handleSearchClick} : SearchUserAdminInterface) {

	const { schoolUsers } = useSchoolAdminModalContext()

	const [currentSearch, setCurrentSearch] = useState<string>('');
	const [filteredUsers, setFilteredUsers] = useState<UserInterface[]>([]);

	const inputUsersSearchRef = useRef<HTMLInputElement>(null);
	const searchUsersResultsRef = useRef<HTMLDivElement>(null);
	const [popupStyle, setPopupStyle] = useState<{ left: number; top: number; width: number } | null>(null);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setCurrentSearch(searchValue);

		if (searchValue.trim() === '') {
			setFilteredUsers([]);
		} else {
			const filtered = FilterUserForSearch(schoolUsers, currentSearch, groupUsers)
			setFilteredUsers(filtered);
		}
	};

	useEffect(() => {
		const inputElement = inputUsersSearchRef.current;
		if (inputElement) {
			const rect = inputElement.getBoundingClientRect();
			setPopupStyle({
				left: rect.left + 25,
				top: rect.bottom + window.scrollY - 1,
				width: rect.width - 55
			});
		}
	}, [currentSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchUsersResultsRef.current &&
				!searchUsersResultsRef.current.contains(event.target as Node) &&
				inputUsersSearchRef.current &&
				!inputUsersSearchRef.current.contains(event.target as Node)
			) {
				setFilteredUsers([]);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchUsersResultsRef]);

	const handleInternalClick = (user : UserInterface) => {
		handleSearchClick(user)
		setCurrentSearch('')
		setFilteredUsers([])
	}

	return (
		<>
			<div className="SearchStudentDiv">
				<input
					ref={inputUsersSearchRef}
					value={currentSearch}
					onChange={handleSearchChange}
					placeholder="Agregar usuario..."
					className="AddUserSearch"
					autoComplete='studentSearch'
					name="searchStudent"
				/>
			</div>
			{filteredUsers.length > 0 && popupStyle && (
				<div
					className="StudentSearchResults"
					style={{ left: popupStyle.left, top: popupStyle.top, width: popupStyle.width }}
					ref={searchUsersResultsRef}
				>
					<ul className="StudentSearchList">
						{filteredUsers.map((user, index) => (
							<li key={index}
								className={`StudentSearchItem`}
								onClick={() => handleInternalClick(user)}>
								{user.email ? user.email : user.SK}
							</li>
						))}
					</ul>
				</div>
			)}
		</>
	)
}

export default SearchUserAdmin