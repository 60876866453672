import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";

export async function FetchGetFunction(url: string): Promise<any> {
	try {
		const jwt = await GetJWTForFetchFunction();
		if (jwt) {
			const getConfig = {
				headers: {
					Authorization: `Bearer ${jwt}`,
				},
			};
			const response = await axios.get(url, getConfig);
			return response.data
		}
		throw new Error('Error en la solicitud GET');
	} catch (error) {
		console.log('Error al realizar la solicitud GET:', error);
		throw new Error('Error en la solicitud GET');
	}
}

export async function FetchPostFunction(url: string, data: any): Promise<any> {
	try {
		const jwt = await GetJWTForFetchFunction();
		if (jwt) {
			const postConfig = {
				headers: {
					Authorization: `Bearer ${jwt}`,
					'Content-Type': 'application/json',
				},
			};
			const response = await axios.post(url, data, postConfig);
			return response.data
		}
		throw new Error('Error en la solicitud POST');
	} catch (error) {
		console.log('Error al realizar la solicitud POST:', error);
		throw new Error('Error en la solicitud POST');
	}
}


export async function FetchPutFunction(url: string, data: any): Promise<any> {
	try {
		const jwt = await GetJWTForFetchFunction();

		if (jwt) {
			const putConfig = {
				headers: {
					Authorization: `Bearer ${jwt}`,
					'Content-Type': 'application/json',
				},
			};
			const response = await axios.put(url, data, putConfig);
			return response.data
		}
		throw new Error('Error en la solicitud PUT');
	} catch (error) {
		console.log('Error al realizar la solicitud PUT:', error);
		throw new Error('Error en la solicitud PUT');
	}
}

export async function FetchDeleteFunction(url: string): Promise<any> {
	try {
		const jwt = await GetJWTForFetchFunction();
		if (jwt) {
			const deleteConfig = {
				headers: {
					Authorization: `Bearer ${jwt}`,
				},
			};
			const response = await axios.delete(url, deleteConfig);
			return response.data;
		}
		throw new Error('Error en la solicitud DELETE');
	} catch (error) {
		console.log('Error al realizar la solicitud DELETE:', error);
		throw new Error('Error en la solicitud DELETE');
	}
}




export async function GetJWTForFetchFunction() {
	const session = await fetchAuthSession();
	return session.tokens?.accessToken
}