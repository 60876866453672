import { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useAdminModalContext } from "../../../Contexts/AdminModalContext/AdminModalProvider"
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import { ViewSingularSchoolComponentInterface } from "../../../Interfaces/ComponentsInterfaces/AdministratorInterfaces"
import ConfirmationPopup from "../../SharedComponents/OtherComponents/ConfirmationPopUp"
import { FetchDeleteFunction } from "../../../Helpers/FetchHelpers"
import defaultSchoolImage from '../../../Assets/BasicSchoolLogo.png'
import "../../../Modals/AdminModals/AdminCSS.css"




function ViewSingularSchool({ schoolToShow = undefined, setSchoolToEdit }: ViewSingularSchoolComponentInterface) {

	const { adminSchools, setAdminSchools, setHasSelectedSchool } = useAdminModalContext()
	const { setCurrentModal, setSchoolOfUser, user, setUser } = useGeneralContext()
	const { updateBasicFunctions } = useSchoolAdminModalContext()
	const [confirmationPopUp, setConfirmationPopUp] = useState<boolean>(false)

	const handleEditClick = () => {
		if (schoolToShow) {
			setSchoolToEdit(schoolToShow)
			setCurrentModal('SchoolEdit')
		}
	}

	const handleClick = () => {
		if (!schoolToShow) {
			return
		}
		setSchoolOfUser(schoolToShow)
		setCurrentModal('HomeModal');
		setHasSelectedSchool(true)
		updateBasicFunctions(schoolToShow)
	}

	const handleDelete = async () => {
		if (schoolToShow) {
			try {
				const url = `${process.env.REACT_APP_BACKEND_URL}/schools/${schoolToShow.SK}`
				await FetchDeleteFunction(url)
				await updateContextSchools()
				alert('Colegio eliminado correctamente')
			} catch (error) {
				alert('Hubo un problema al eliminar el colegio')
			}
		}
	}

	const updateContextSchools = async () => {
		if (schoolToShow) {
			const updatedSchools = adminSchools.filter(schoolToFilter => schoolToFilter.SK !== schoolToShow.SK)
			setAdminSchools(updatedSchools)
		}
	}

	return (
		schoolToShow ? (
			<>
				<div className="ViewSchoolDiv">
					<div className="ViewSchoolImageDiv" onClick={handleClick}>
						{(schoolToShow.profile_image && schoolToShow.profile_image.trim() !== '') ? (
							<img src={schoolToShow.profile_image} alt='Logo por defecto2' />
						) : (
							<img src={defaultSchoolImage} alt='Logo por defecto' />
						)}
					</div>
					<div className="ViewSchoolNameDiv" onClick={handleClick}>
						<h5>{schoolToShow.name}</h5>
					</div>
					<div className="ViewSchoolButtonDiv">
						<button type='button' onClick={handleEditClick}>
							Editar
						</button>
						<button type='button' onClick={() => setConfirmationPopUp(true)}>
							Borrar
						</button>
					</div>
				</div>
				{confirmationPopUp && (
					<ConfirmationPopup
						valueToConfirmate={schoolToShow.name}
						deletionType="school"
						deleteFunction={handleDelete}
						setIsPopUpVisible={setConfirmationPopUp}
					/>
				)}
			</>
		) : (
			<div className='ViewEmptySchoolDiv'></div>
		)
	)
}

export default ViewSingularSchool