import "./LandingPage.css"
import LandingNavBar from "../../Components/LandingPageComponents/Navbar/LandingNavBar";
import ContactSection from "../../Components/LandingPageComponents/ContactSection";
import LandingFooter from "../../Components/LandingPageComponents/Footer/LandingFooter";
import { useEffect, useState } from "react";
import LandingModalContainer from "../../Modals/LandingModals/LandingModalContainer";

function LandingPage() {

	const [currentModal, setCurrentModal] = useState<'WhatIs' | 'AboutUs' | 'Home'>('Home')

	const handleScrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	useEffect(() => {
		handleScrollToTop();
	}, [currentModal]);
	

	return (
		<div className="landing-page">
			<LandingNavBar
				setCurrentModal={setCurrentModal}
			/>
			<LandingModalContainer
				currentModal={currentModal}
				setCurrentModal={setCurrentModal}
			/>
			<ContactSection />
			<LandingFooter />
		</div>
	)
}

export default LandingPage;