import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import ListMyGroups from "../../../Components/SharedComponents/ListData/ListMyGroups";
import { useEffect } from "react";

function UserGroupListModal() {

	const { groupsOfUser, updateGroupsOfUser, schoolOfUser } = useGeneralContext();

	useEffect(() => {
		updateGroupsOfUser(schoolOfUser?.SK)
	}, [])

	return (
		<>
			<ListMyGroups 
				groupsToShow={groupsOfUser}
			/>
		</>
	)
}

export default UserGroupListModal