import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { CreateNoticeComponentInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import { GroupOfUserInterface, NoticeInterface } from "../../../Interfaces/Entities";
import { FormatDate } from "../../../Helpers/DateHelpers";
import { CheckIfNewsLetterIsComplete } from "../../../Helpers/CheckHelpers";
import { FetchPostFunction } from "../../../Helpers/FetchHelpers";


function CreateNewsLetterComponent( { updateContext } : CreateNoticeComponentInterface) {

	const { schoolOfUser, groupsOfUser, user } = useGeneralContext()
	const [currentTitle, setCurrentTitle] = useState<string>('')
	const [currentGroup, setCurrentGroup] = useState<string>('')
	const [selectedGroup, setSelectedGroup] = useState<GroupOfUserInterface | undefined>(undefined)
	const [currentDescription, setCurrentDescription] = useState<string>('')

	const handleGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const groupId = e.target.value;
		setCurrentGroup(groupId);
		const group = groupsOfUser.find((group) => group.group_id === groupId);
		setSelectedGroup(group);
	};


	const handleSubmit = async () => {
		if (!user || !schoolOfUser) {
			return
		}
		if (CheckIfNewsLetterIsComplete(currentGroup, selectedGroup, currentTitle, currentDescription)) {
			if (!selectedGroup) {
				return
			}
			try {
				const url = `${process.env.REACT_APP_BACKEND_URL}/news?` +
					`user_id=${user.SK}&school_id=${schoolOfUser.SK}`
				const attrs = {
					groups: [{ name: selectedGroup.label, id: selectedGroup.group_id }],
					type: 'General',
					description: currentDescription,
					notify: true,
					user: user.name,
					position: user.position,
					solved: true,
					title: currentTitle
				};
				await FetchPostFunction(url, { 'attrs': attrs })
				const newNotice  : NoticeInterface = { 
					type: 'General',
					location: '',
					description: currentDescription, 
					user_id: user.SK,
					position: user.position,
					solved: true,
					groups: [{ name: selectedGroup.label, id: selectedGroup.group_id }],
					notify: true,
					comments : [],
					images: [],
					date: FormatDate(new Date()),
					SK: FormatDate(new Date()),
					title: currentTitle
				}
				updateContext(newNotice)
				alert('Noticia correctamenete creada')
			} catch (error) {
				alert('Hubo un problema al crear la noticia')
			}
		}
	}

	return (
		<div className="CreateNewsLetterMainDiv">
			<div className="CreateNewsLetterSubMainDiv SmallNewsLetterTextArea">
				<h4>Indica el título de la nota</h4>
				<textarea
					value={currentTitle}
					onChange={(e) => setCurrentTitle(e.target.value)}
				/>
			</div>
			<div className="CreateNewsLetterSubMainDiv">
				<h4>Selecciona el grupo a quien va dirigido</h4>
				<select
					value={currentGroup}
					onChange={handleGroupChange}
					className="CreateNewsLetterSelect"
				>
					<option value="" disabled>{'Selecciona un grupo'}</option>
					{groupsOfUser.map((group: GroupOfUserInterface) => (
						<option value={group.group_id}>{group.label}</option>
					))}
				</select>
			</div>
			<div className="CreateNewsLetterSubMainDiv BigNewsLetterTextArea">
				<h4>Escribe tu mensaje</h4>
				<textarea
					value={currentDescription}
					onChange={(e) => setCurrentDescription(e.target.value)}
				/>
			</div>
			<div className="CreateNewsLetterSubMainDiv">
				<button type='button' onClick={handleSubmit}>
					Crear Nota
				</button>
			</div>
		</div>
	)
}

export default CreateNewsLetterComponent