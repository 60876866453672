import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { NoticeInterface } from "../../../Interfaces/Entities"
import ViewSingularNotification from "../ViewData/ViewSingularNotification"
import { AreTwoDatesTheSameDay } from "../../../Helpers/DateHelpers"
import { FilterThisWeekNotices } from "../../../Helpers/FilterFuntions"
import '../SharedComponents.css'


function GroupHomeNewsLetters() {

	const { savedNewsLetters } = useUserModalContext()
	const { setCurrentModal } = useGeneralContext()
	const todayNewsLetters = savedNewsLetters.filter((notice: NoticeInterface) => AreTwoDatesTheSameDay(new Date(), 
		new Date(notice.date)))
	const thisWeekNewsLetters = FilterThisWeekNotices(savedNewsLetters)

	return (
		<>
			<div className="GroupHomeTodayNewsLetters">
				<div className="GroupHomeNewsLettersTitles FirstAlertDiv">
					<h4>Notas de hoy</h4>
					<button type='button' onClick={() => setCurrentModal('NoticeList')}>
						Ver Todas
					</button>
				</div>
				<div className="GroupHomeTodayNewsLettersData">
					{todayNewsLetters.length === 0 ? (
						<h1>No hay boletines hoy</h1>
					) : (
						todayNewsLetters.slice(0, 2).map((notice) => (
							<ViewSingularNotification
								noticeToShow={notice}
								notificationType="UnResolved"
								boxSize="SmallerNotification"
							/>
						))
					)}
				</div>
			</div>
			<div className="GroupHomeThisWeekNewsLetters  NotFirstAlertDiv">
				<div className="GroupHomeNewsLettersTitles">
					<h4>Última semana</h4>
					<button type='button' onClick={() => setCurrentModal('NoticeList')}>
						Ver Todas
					</button>
				</div>
				<div className="GroupHomeThisWeekNewsLettersData">
					{thisWeekNewsLetters.length >= 3 ? (
						<>
							<div className="GroupHomeThisWeekNewsLettersInternalData1">
								{thisWeekNewsLetters.slice(0, 2).map((notice) => (
									<ViewSingularNotification
										noticeToShow={notice}
										notificationType="Resolved"
										boxSize="SmallerNotification"
									/>
								))}
							</div>
							<div className="GroupHomeThisWeekNewsLettersInternalData2">
								{thisWeekNewsLetters.slice(2, 4).map((notice) => (
									<ViewSingularNotification
										noticeToShow={notice}
										notificationType="Resolved"
										boxSize="SmallerNotification"
									/>
								))}
							</div>
						</>
					) : (
						thisWeekNewsLetters.length === 0 ? (
							<h1>No hay boletines esta semana</h1>
						) : (
							<div className="GroupHomeThisWeekNewsLettersInternalData1">
								{thisWeekNewsLetters.slice(0, 2).map((notice) => (
									<ViewSingularNotification
										noticeToShow={notice}
										notificationType="Resolved"
										boxSize="SmallerNotification"
									/>
								))}
							</div>
						)
					)}
				</div>
			</div>
		</>
	)
}

export default GroupHomeNewsLetters