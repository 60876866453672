import { useEffect, useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { SchoolInterface } from "../../../Interfaces/Entities"
import ViewSingularEmergency from "../ViewData/ViewSingularEmergency"
import { FetchPutFunction } from "../../../Helpers/FetchHelpers"
import '../SchoolAdmin.css'


function MapEmergencies() {

	const { schoolOfUser, setSchoolOfUser } = useGeneralContext()
	const [currentEmergencies, setCurrentEmergencies] = useState<string[]>(schoolOfUser ? schoolOfUser.types : [])
	const [hasMounted, setHasMounted] = useState<boolean>(false)

	const updateSchoolEmergencies = async () => {
		if (!schoolOfUser) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/schools/${schoolOfUser.SK}`;
			const body = {
				"attrs": {
					"types": currentEmergencies,
				}
			};
			await FetchPutFunction(url, body)
			const updatedSchool: SchoolInterface = { ...schoolOfUser, types: currentEmergencies }
			setSchoolOfUser(updatedSchool)
		} catch { }
	}

	const updateEmergenciesIfNeeded = async () => {
		if (hasMounted && !currentEmergencies.includes("Emergencia de ejemplo")) {
			await updateSchoolEmergencies();
		} else {
			setHasMounted(true)
		}
	};

	useEffect(() => {
		updateEmergenciesIfNeeded();
	}, [currentEmergencies])

	const createEmergency = () => {
		const updatedEmergencies = [
			...currentEmergencies.filter(emergency => emergency !== 'General'),
			'Emergencia de ejemplo',
			'General'
		];
		setCurrentEmergencies(updatedEmergencies)
	}

	return (
		<div className="MapEmergenciesMainDiv">
			{currentEmergencies.map((emergency: string, index: number) => {
				if (emergency !== 'General') {
					return (
						< ViewSingularEmergency
							emergency={emergency}
							index={index}
							emergencies={currentEmergencies}
							setCurrentEmergencies={setCurrentEmergencies}
						/>)
				}
			})}
			<button type='button' className="CreateEmergencieButton" onClick={createEmergency}>
				Crear nueva emergencia
			</button>
		</div>
	)
}

export default MapEmergencies