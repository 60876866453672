import { useState } from "react"
import { NoticeFilterInterface } from "../../../Interfaces/Entities"
import GroupHistoryNotices from "../../../Components/SharedComponents/GroupData/GroupHistoryNotices"
import GroupHistoryLetters from "../../../Components/SharedComponents/GroupData/GroupHistoryNewsLetters"
import FilterAlerts from "../../../Components/SharedComponents/FilterData/FilterAlerts"
import '../UserModalCSS.css'


function UserHistoryModal() {

	const [currentHistory, setCurrentHistory] = useState<'Notices' | 'NewsLetters'>('Notices')
	const [isShowingFilters, setIsShowingFilters] = useState<boolean>(false)
	const [selectedFilters, setSelectedFilters] = useState<NoticeFilterInterface>(
		{ FilterGroup: [], FilterType: [], FilterState: [], startDate: undefined, endDate: undefined }
	)
	const [isFilterActive, setIsFilterActive] = useState<boolean>(false)
	const [filterActivator, setFilterActivator] = useState<number>(0)

	const cleanFilters = () => {
		setIsFilterActive(false)
		resetFilters()
	}

	const resetFilters = () => {
		setSelectedFilters({ FilterGroup: [], FilterType: [], FilterState: [], startDate: undefined, endDate: undefined })
	}

	return (
		<>
			<div
				className={`UserHistorySuperiorDiv `
					+ `${(isShowingFilters && currentHistory === 'Notices') && 'UserHistorySuperiorDivBorder'}`}
			>
				<div className="UserHistoryMainButtonDiv">
					<button
						type='button'
						className={currentHistory === 'Notices' ? 'HomeModalSelectedType' : 'HomeModalUnselectedType'}
						onClick={() => setCurrentHistory('Notices')}
					>
						Avisos
					</button>
					<button
						type='button'
						className={currentHistory === 'NewsLetters' ? 'HomeModalSelectedType' : 'HomeModalUnselectedType'}
						onClick={() => setCurrentHistory('NewsLetters')}
					>
						Boletin
					</button>
				</div>
				{currentHistory === 'Notices' && (
					<div className="UserHistorySecondaryButtonDiv">
						<button
							type='button'
							className="FilterButton"
							onClick={() => cleanFilters()}
							hidden={!isFilterActive}
						>
							Limpiar Filtros
						</button>
						<button
							type='button'
							className="FilterButton"
							onClick={() => resetFilters()}
							hidden={!isShowingFilters}
						>
							Reiniciar Filtros
						</button>
						<button
							type='button'
							className="FilterButton"
							onClick={() => setIsShowingFilters(!isShowingFilters)}
						>
							Filtros
						</button>
					</div>
				)}
			</div>
			{(isShowingFilters && currentHistory === 'Notices') && (
				<FilterAlerts
					filters={selectedFilters}
					setFilters={setSelectedFilters}
					filterActivator={filterActivator}
					setFilterActivator={setFilterActivator}
					setIsFilterActive={setIsFilterActive}
					setIsShowingFilters={setIsShowingFilters}
				/>
			)}
			<div className="UserHistoryNoticesBox">
				{currentHistory === 'Notices' && (
					<GroupHistoryNotices
						filters={selectedFilters}
						isApplingFilters={isFilterActive}
						filterActivator={filterActivator}
					/>
				)}
				{currentHistory === 'NewsLetters' && (
					<GroupHistoryLetters />
				)}
			</div>
		</>
	)
}

export default UserHistoryModal