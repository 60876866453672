import React, { useState } from 'react';
import { UploadPopUpInterface } from '../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces';
import '../SchoolAdmin.css'

function UploadPopUp({ onClose, onSubmit }: UploadPopUpInterface) {
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
		const file = event.target.files ? event.target.files[0] : null;
		setSelectedFile(file);
	}

	function handleCancel() {
		setSelectedFile(null);
		onClose();
	}

	async function handleSubmit() {
		if (selectedFile) {
			await onSubmit(selectedFile);
			setSelectedFile(null);
			onClose();
		} else {
			alert('Por favor, selecciona un archivo');
		}
	}

	return (
		<div className="popup-container">
			<div className="popup-content">
				<h1>Subir archivo Excel o CSV</h1>
				<input
					type="file"
					accept={".csv, application/vnd.ms-excel, " + 
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
					onChange={handleFileChange}
				/>
				<div className="popup-buttons">
					<button onClick={handleSubmit} disabled={!selectedFile}>Enviar</button>
					<button onClick={handleCancel}>Cancelar</button>
				</div>
			</div>
		</div>
	);
}

export default UploadPopUp;
