import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import { UserInterface } from "../../../Interfaces/Entities"
import MapUser from "../MapData/MapUser"
import '../SchoolAdmin.css'

function ListAllUsers() {

	const { schoolUsers } = useSchoolAdminModalContext()

	return (
		<table className="AllUsersTable">
			<thead>
				<tr>
					<th> # </th>
					<th> Nombre usuario</th>
					<th> Correo electrónico </th>
					<th>  </th> {/* View button column*/}
					<th>  </th> {/* Delete button column*/}
				</tr>
			</thead>
			<tbody>
				{schoolUsers.map((user : UserInterface, index : number) => (
					<MapUser 
						user={user}
						index={index}
					/>
				))}
			</tbody>
		</table>
	)
}

export default ListAllUsers