import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import EditPassword from "../../../Components/SharedComponents/EditData/EditPassword";
import ListUserAgenda from "../../../Components/SharedComponents/ListData/ListUserAgenda";
import ViewUsersData from "../../../Components/SharedComponents/ViewData/ViewUsersData";


function UserProfileModal() {

	const { user, groupsOfUserAsGroups } = useGeneralContext();

	return (
		<>
			<ViewUsersData 
				userToShow={user}
				groupsOfUser={groupsOfUserAsGroups}
			/>
			<ListUserAgenda 
				userToShow={user}
			/>
			<EditPassword 
				userToShow={user}
			/>
		</>
	)
}

export default UserProfileModal