import { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import '../UserModalCSS.css'


function UserReportsModal() {

	const { setCurrentModal } = useGeneralContext()
	const [viewReports, setViewReports] = useState<'Individual' | 'Group'>('Individual')

	const handleCreateButton = () => {
		if (viewReports === 'Individual') {
			setCurrentModal('GetIndividualReport')
		} else {
			setCurrentModal('GetGrupalReport')
		}
	}

	return (
		<>
			<div className="ReportUsersSuperiorDiv BottomLine">
				<div className="ReportUserSuperiorSubDiv">
					<button
						type='button'
						className={viewReports === 'Individual' ? 'ReportUserSelectedType' : 'ReportUserUnselectedType'}
						onClick={() => setViewReports('Individual')}
					>
						Individuales
					</button>
					<button
						type='button'
						className={viewReports === 'Group' ? 'ReportUserSelectedType' : 'ReportUserUnselectedType'}
						onClick={() => setViewReports('Group')}
					>
						Grupos
					</button>
				</div>
				<div className="ReportUserSuperiorSubDiv">
					<button 
						type='button' className="ReportUserCreateButton"
						onClick={handleCreateButton}
					>
						Solicitar Informes
					</button>
				</div>
			</div>
		</>
	)
}

export default UserReportsModal