import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { ViewCommentsOfNoticeInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { CommentInterface, GroupedCommentInterface, NoticeInterface, SingularCommentSummary }
	from "../../../Interfaces/Entities"
import { FormatCommentsForChat, FormatCommentsForNewComment } from "../../../Helpers/FormatHelpers"
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers"
import { FetchPostFunction } from "../../../Helpers/FetchHelpers"
import BasicUserIcon from '../../../Assets/BasicUserIcon.png'
import ClipIcon from '../../../Assets/ClipIcon.png'
import '../SharedComponents.css'



function ViewCommentsOfNotice({ }: ViewCommentsOfNoticeInterface) {

	const { user, schoolOfUser } = useGeneralContext()
	const { allNotices, setAllNotices, setNoticeToSee, noticeToSee } = useUserModalContext()
	
	const [newComment, setNewComment] = useState<string>('')

	const fastComments = ['Voy ahora', 'No puedo ir', 'Voy en breve']
	const notFastComments = noticeToSee.comments.filter((comment: CommentInterface) =>
		!fastComments.includes(comment.comment)
	)
	const groupedComments: GroupedCommentInterface[] = FormatCommentsForChat(notFastComments)
	const noCommentMessage = `Este ${noticeToSee.type === 'General' ? 'boletin' : 'aviso'} no tiene comentarios`

	const [fileToUpload, setFileToUpload] = useState<File[]>([])
	const [fileName, setFileName] = useState<string | null>(null);


	const createComment = async () => {
		if (!user || !schoolOfUser) {
			return
		}
		if (fileToUpload.length === 0 && newComment.trim() === '') {
			alert('No puede crear un comentario vacio')
			return
		}
		try {
			const commentToCreate: CommentInterface = {
				comment: newComment,
				user_id: user.SK,
				user_name: user.name
			}
			const url = `${process.env.REACT_APP_BACKEND_URL}/comments?`
			const previusComments = FormatCommentsForNewComment(noticeToSee.comments, schoolOfUser.SK)
			const body = {
				SK: noticeToSee.SK,
				school_id: schoolOfUser.SK,
				attrs: {
					comments: [...previusComments, commentToCreate]
				},
				notify: noticeToSee.notify ? noticeToSee.notify : false,
				notify_attrs: {
					type: noticeToSee.type,
					groups: noticeToSee.groups,
					user: user.name,
					description: noticeToSee.description,
				}
			}
			if (fileToUpload.length > 0) {
				const filePromises = fileToUpload.map(file => PrepareFileToSend(file));
				const fileData = await Promise.all(filePromises);
				body['comment_file'] = fileData
			}
			await FetchPostFunction(url, body)
			updateNoticesForComment(commentToCreate)
			alert("Comentario creado correctamente")
			setNewComment('')
			setFileToUpload([])
		} catch (error) {
			alert('Hubo un problema al crear un comentario rápido')
		}
	}

	const updateNoticesForComment = (newComment: CommentInterface) => {
		const updatedNotice = { ...noticeToSee, comments: [...noticeToSee.comments, newComment] }
		const updatedNotices = allNotices.map((notice: NoticeInterface) =>
			notice.SK === noticeToSee.SK ? updatedNotice : notice
		)
		setNoticeToSee(updatedNotice)
		setAllNotices(updatedNotices)
	}

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files || []);
		setFileToUpload(files);
		if (files && files.length > 0) {
			setFileName(files[0].name);
		}
	};


	return (
		<>
			<div className="CommentShowMainDiv">
				{notFastComments.length === 0 ? (
					<h1>{noCommentMessage}</h1>
				) : (
					<div className="CommentShowSubMainDiv">
						{groupedComments.map((groupedComment: GroupedCommentInterface) => {
							if (user && groupedComment.user_id === user.SK) {
								return (
									groupedComment.comments.map((commentSummary: SingularCommentSummary) => (
										<div className="IndividualCommentOwnerMainDiv">
											<div className="IndividualCommentText IndividualCommentTextOwner">
												<img src={commentSummary.image} alt="profile"
													hidden={!commentSummary.image} />
												<h2>{commentSummary.comment}</h2>
												<h4>{commentSummary.date}</h4>
											</div>
										</div>
									))
								)
							} else {
								return (
									<div className="IndividualCommentMainDiv">
										<div className="IndividualCommentProfilePicture">
											<img src={BasicUserIcon} alt="profile" />
										</div>
										<div className="IndividualCommentTextArea">
											<h2>{groupedComment.user_id.split("@")[0]}</h2>
											{groupedComment.comments.map((commentSummary: SingularCommentSummary) => (
												<div className="IndividualCommentText IndividualCommentTextNotOwner">
													<img src={commentSummary.image} alt="profile"
														hidden={!commentSummary.image} />
													<h2>{commentSummary.comment}</h2>
													<h4>{commentSummary.date}</h4>
												</div>
											))}
										</div>
									</div>
								)
							}
						})}
					</div>
				)}
			</div>
			<div className="CommentInputMainDiv">
				{fileToUpload.length >= 1 && (
					<div className="CommentInputFileDiv">
						<h4>Archivo cargado: {fileName}</h4>
						<button type="button" onClick={() => setFileToUpload([])}>X</button>
					</div>
				)}
				<div className="CommentInputSubDiv">
					<input
						type="text"
						placeholder="Escribe tu comentario"
						value={newComment}
						onChange={(e) => setNewComment(e.target.value)}
					/>
					<div className="CommentInputSubButtonDiv">
						<button onClick={() => createComment()}>
							Dejar comentario
						</button>
						<label htmlFor="file-upload" className="custom-file-upload">
							<img src={ClipIcon} alt="" />
						</label>
						<input
							id="file-upload"
							type="file"
							multiple
							accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.txt,.HEIF"
							onChange={handleFileChange}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
							style={{ display: 'none' }}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default ViewCommentsOfNotice