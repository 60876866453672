import ContactPicture from '../../Assets/HowLCWorksImage1.png'
import UploadPicture from '../../Assets/HowLCWorksImage2.png'
import DefinePriorities from '../../Assets/HowLCWorksImage3.png'
import CreateNotices from '../../Assets/HowLCWorksImage4.png'
import GetReports from '../../Assets/HowLCWorksImage5.png'
import '../../Modals/LandingModals/LandingModalsCSS.css'


function HowLinkClassWork() {
	return (
		<section id='howLinkClassWork' className="howLCworks-section">
			<div className='howLCworks-title'>
				<h1>¿Cómo funciona?</h1>
			</div>
			<div className='howLCworks-div'>
				<img src={ContactPicture} alt='Contacto' />
				<div className='text-div'>
					<h1>
						1. Contáctanos
					</h1>
					<h3>
						para que podamos reunirnos contigo y conocer las necesidades de tu establecimiento. Una vez
						tengamos la información necesaria, crearemos tu perfil de administrador para que puedas empezar
						a usar LinkClass de inmediato.
					</h3>
				</div>
			</div>
			<div className='howLCworks-div'>
				<div className='text-div'>
					<h1>
						2. Importa tus bases
					</h1>
					<h3>
						de los alumnos y colaboradores de tu establecimiento con nuestras plantillas de Excel.
						Los usuarios y grupos se generarán automáticamente.
					</h3>
				</div>
				<img src={UploadPicture} alt='Ingreso de bases' />
			</div>
			<div className='howLCworks-div'>
				<img src={DefinePriorities} alt='Define Priorities' />
				<div className='text-div'>
					<h1>
						3. Define las Prioridades
					</h1>
					<h3>
						para notificar a los usuarios según el Grupo y tipo de Situación Crítica. Puedes
						definir plantillas y editarlas caso a caso.
					</h3>
				</div>
			</div>
			<div className='howLCworks-div'>
				<div className='text-div'>
					<h1>
						4. Crea nuevos Avisos
					</h1>
					<h3>
						en la app y el sistema notificará automáticamente a la persona más indicada,
						registrando el avance de la situación para futura referencia.
					</h3>
				</div>
				<img src={CreateNotices} alt='Crear Avisos' />
			</div>
			<div className='howLCworks-div'>
				<img src={GetReports} alt='Obtener reportes' />
				<div className='text-div'>
					<h1>
						5. Genera Informes
					</h1>
					<h3>
						para ver un registro de los avisos pertinentes a un alumno o Grupo, y compártelo
						con apoderados u otros profesionales de tu establecimiento.
					</h3>
				</div>
			</div>
		</section>
	)
}

export default HowLinkClassWork