import HeroSection from "../../../Components/LandingPageComponents/HeroSection"
import HowLinkClassWork from "../../../Components/LandingPageComponents/HowLinkClassWork"


function WhatIsModal() {
	return (
		<>
			<HeroSection />
			<HowLinkClassWork />
		</>
	)
}

export default WhatIsModal