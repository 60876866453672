import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider";
import { useAdminModalContext } from "../../Contexts/AdminModalContext/AdminModalProvider";
import { OneSignalLogOut } from "../../Helpers/OneSignalHelpers";
import { FormatSelecterModalText } from "../../Helpers/ReactHelpers";
import LinkClassLogo from '../../Assets/LinkClassLogo.png'
import ProfileLogo from '../../Assets/ProfileLogo.png'
import LogOutLogo from '../../Assets/ProfileLogo.png'
import CreateNoticeIcon from '../../Assets/CreateNoticeIcon.png'
import HomeIcon from '../../Assets/HomeIcon.png'
import HistoryIcon from '../../Assets/HistoryIcon.png'
import ReportIcon from '../../Assets/ReportIcon.png'
import GroupIcon from '../../Assets/GroupsIcon.png'
import SuperAdminIcon from '../../Assets/SchoolModalIcon.png'
import EmergencyIcon from '../../Assets/EmergenciesIcon.png'
import AllGroupsIcon from '../../Assets/AllGroupsIcon.png'
import UserStudentIcon from '../../Assets/UsersAndStudentsIcon.png'
import '../../index.css'



function AdminModalSelecter() {

	const { currentModal, setCurrentModal, setHasLogged } = useGeneralContext()
	const { hasSelectedSchool } = useAdminModalContext()
	const navigate = useNavigate();

	const handleLogOut = async () => {
		sessionStorage.setItem('lastLocation', '/');
		setHasLogged(false)
		navigate("/")
		setCurrentModal('HomeModal')
		await signOut({ global: true });
		await OneSignalLogOut()
	}

	return (
		<>
			<div className="PageLogo" onClick={() => setCurrentModal('SchoolList')}>
				<img src={LinkClassLogo} alt="LinkClass" />
			</div>
			<div className="PageModalSelectorAdmin">
				<div className="FullPageSelector BottomLine">
					<div className="FullSubPage">
						<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('SchoolList')}>
							<img src={SuperAdminIcon} alt="CreateNoticeIcon" />
							<div className="ModalSelectorSubDiv">
								{FormatSelecterModalText('Superadmin', 'SchoolList', currentModal)}
							</div>
						</div>
					</div>
				</div>

				{hasSelectedSchool && (
					<>
						<div className="FullPageSelector BottomLine">
							<div className="FullSubPage">
								<div className="ModalSelectorButtonDiv MainButtonDiv"
									onClick={() => setCurrentModal('CreateNotice')}>
									<img src={CreateNoticeIcon} alt="CreateNoticeIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Nuevo aviso', 'CreateNotice', currentModal)}
									</div>
								</div>
								<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('HomeModal')}>
									<img src={HomeIcon} alt="HomeIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Entrada', 'HomeModal', currentModal)}
									</div>
								</div>
								<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('NoticeList')}>
									<img src={HistoryIcon} alt="HistoryIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Historial', 'NoticeList', currentModal)}
									</div>
								</div>
								<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('GroupList')}>
									<img src={GroupIcon} alt="GroupIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Mis grupos', 'GroupList', currentModal)}
									</div>
								</div>
								<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('HomeReport')}>
									<img src={ReportIcon} alt="ReportIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Informes', 'HomeReport', currentModal)}
									</div>
								</div>
							</div>
						</div>
						<div className="FullPageSelector">
							<div className="FullSubPage">
								<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('EmergencyTypes')}>
									<img src={EmergencyIcon} alt="HistoryIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Situaciones críticas', 'EmergencyTypes', currentModal)}
									</div>
								</div>
								<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('AllGroupList')}>
									<img src={AllGroupsIcon} alt="GroupIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Grupos', 'AllGroupList', currentModal)}
									</div>
								</div>
								<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('StudentsAndUsers')}>
									<img src={UserStudentIcon} alt="ReportIcon" />
									<div className="ModalSelectorSubDiv">
										{FormatSelecterModalText('Usuarios y Alumnos', 'StudentsAndUsers', currentModal)}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div className="PageAccountSettings">
				<div className="PageAccountSettingsInternal">
					<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('Profile')}>
						<img src={ProfileLogo} alt="ProfileLogo" />
						<div className="ModalSelectorSubDiv">
							{FormatSelecterModalText('Ver mi Perfil', 'Profile', currentModal)}
						</div>
					</div>
					<div className="ModalSelectorButtonDiv" onClick={handleLogOut}>
						<img src={LogOutLogo} alt="LogOutLogo" />
						<div className="ModalSelectorSubDiv">
							Cerrar sesión
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AdminModalSelecter