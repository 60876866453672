import { ListMyGroupsInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { GroupOfUserInterface } from "../../../Interfaces/Entities"
import SummaryMyGroup from "../SummaryData/SummaryMyGroup"
import '../SharedComponents.css'


function ListMyGroups( {groupsToShow} : ListMyGroupsInterface) {
	return (
		<div className="ListMyGroupsMainDiv">
			{groupsToShow.map((group : GroupOfUserInterface) => (
				<SummaryMyGroup
					groupToShow={group}
				/>
			))}
		</div>
	)
}

export default ListMyGroups