import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider";
import NavBar from "../../Others/NavBar/NavBar";
import SummarySuperiorSchool from "../../Components/SharedComponents/SummaryData/SummarySuperiorSchool";
import ViewSchoolAdminExtraButtons from "../../Components/SchoolAdminModalsComponents/ViewData/ViewSchoolAdminExtraButtons";
import UserHomeModal from "../UserModals/Modals/UserHomeModal";
import UserHistoryModal from "../UserModals/Modals/UserHistoryModal";
import UserSeeNoticeModal from "../UserModals/Modals/UserSeeNoticeModal";
import UserGroupListModal from "../UserModals/Modals/UserGroupListModal";
import UserProfileModal from "../UserModals/Modals/UserProfileModal";
import UserCreateNoticeModal from "../UserModals/Modals/UserCreateNoticeModal";
import UserCreateNewsLetterModal from "../UserModals/Modals/UserCreateNewsLetterModal";
import UserSeeGroupModal from "../UserModals/Modals/UserSeeGroupModal";
import UserReportsModal from "../UserModals/Modals/UserReportsModal";
import UserCreateIndividualReportModal from "../UserModals/Modals/UserCreateIndividualReportModal";
import UserCreateGrupalReportModal from "../UserModals/Modals/UserCreateGrupalReportModal";
import SchoolAdminETModal from "./Modals/SchoolAdminETModal";
import SchoolAdminAllGroupsModal from "./Modals/SchoolAdminAllGroupsModal";
import SchoolAdminCreateGroupModal from "./Modals/SchoolAdminCreateGroupModal";
import SchoolAdminSAUModal from "./Modals/SchoolAdminSAUModal";
import SchoolAdminCreateStudentModal from "./Modals/SchoolAdminCreateStudentModal";
import SchoolAdminCreateUserModal from "./Modals/SchoolAdminCreateUserModal";
import SchoolAdminEditUserModal from "./Modals/SchoolAdminEditUserModal";
import SchoolAdminEditStudentModal from "./Modals/SchoolAdminEditStudentModal";
import { TranslateModalName } from "../../Helpers/TranslateHelpers";
import '../../index.css'


function SchoolAdminModalContainer() {

	const { currentModal, hasLogged, schoolOfUser } = useGeneralContext();
	const navigate = useNavigate()

	useEffect(() => {
		if (!hasLogged) {
			navigate('/')
		}
	}, [])

	return (
		<div className="ModalContainerMainDiv">
			<NavBar />
			<div className="ConteinerModalShower">
				<SummarySuperiorSchool
					schoolToSummary={schoolOfUser}
				/>
				<ViewSchoolAdminExtraButtons />
				<div className="ModalTitleDiv">
					<h1>{TranslateModalName(currentModal)}</h1>
				</div>
				<div className="ModalDisplayer">
					{
						(() => {
							switch (currentModal) {
								case 'HomeModal':
									return <UserHomeModal />
								case 'NoticeList':
									return <UserHistoryModal />
								case 'SeeNotice':
									return <UserSeeNoticeModal />
								case 'GroupList':
									return <UserGroupListModal />
								case 'Profile':
									return <UserProfileModal />
								case 'CreateNotice':
									return <UserCreateNoticeModal />
								case 'CreateNewsLetter':
									return <UserCreateNewsLetterModal />
								case 'SeeGroup':
									return <UserSeeGroupModal />
								case 'HomeReport':
									return <UserReportsModal />
								case 'GetIndividualReport':
									return <UserCreateIndividualReportModal />
								case 'GetGrupalReport':
									return <UserCreateGrupalReportModal />
								case 'EmergencyTypes':
									return <SchoolAdminETModal />
								case 'StudentsAndUsers':
									return <SchoolAdminSAUModal />
								case 'AllGroupList':
									return <SchoolAdminAllGroupsModal />
								case 'CreateGroup':
									return <SchoolAdminCreateGroupModal />
								case 'CreateStudent':
									return <SchoolAdminCreateStudentModal />
								case 'CreateUser':
									return <SchoolAdminCreateUserModal />
								case 'EditUser':
									return <SchoolAdminEditUserModal />
								case 'EditStudent':
									return <SchoolAdminEditStudentModal />
								default:
									return <UserHomeModal />
							}
						})()
					}
				</div>
			</div>
		</div>
	)
}

export default SchoolAdminModalContainer