import { LandingPageContainerInterface } from "../../Interfaces/ComponentsInterfaces/LandingPageComponents";
import HomeMainModal from "./Modals/HomeMainModal";
import WhatIsModal from "./Modals/WhatIsModal";
import AboutUsModal from "./Modals/AboutUsModal";

function LandingModalContainer({ currentModal, setCurrentModal }: LandingPageContainerInterface) {
	switch (currentModal) {
		case 'Home':
			return <HomeMainModal setCurrentModal={setCurrentModal} />
		case 'WhatIs':
			return <WhatIsModal />
		case 'AboutUs':
			return <AboutUsModal />
	}
}

export default LandingModalContainer