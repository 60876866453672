import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider";
import { NoticeInterface } from "../../../Interfaces/Entities";
import CreateNoticeComponent from "../../../Components/SharedComponents/CreateData/CreateNoticeComponent";


function UserCreateNoticeModal() {

	const { allNotices, setAllNotices } = useUserModalContext()
	const { setCurrentModal } = useGeneralContext()

	const updateNotices = (newNotice : NoticeInterface) => {
		const updatedNotices = [newNotice, ...allNotices]
		setAllNotices(updatedNotices)
		setCurrentModal('NoticeList')
	}

	return (
		<CreateNoticeComponent 
			updateContext={updateNotices}
		/>
	)
}

export default UserCreateNoticeModal