import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider";
import { OneSignalLogOut } from "../../Helpers/OneSignalHelpers";
import { FormatSelecterModalText } from "../../Helpers/ReactHelpers";
import CreateNoticeIcon from '../../Assets/CreateNoticeIcon.png'
import HomeIcon from '../../Assets/HomeIcon.png'
import HistoryIcon from '../../Assets/HistoryIcon.png'
import ReportIcon from '../../Assets/ReportIcon.png'
import GroupIcon from '../../Assets/GroupsIcon.png'
import LinkClassLogo from '../../Assets/LinkClassLogo.png'
import ProfileLogo from '../../Assets/ProfileLogo.png'
import LogOutLogo from '../../Assets/ProfileLogo.png'
import "./SchoolAdminCSS.css"
import '../../index.css'



function SchoolAdminModalSelecter() {
	const { currentModal, setCurrentModal, setHasLogged } = useGeneralContext()
	const navigate = useNavigate();

	const handleLogOut = async () => {
		setHasLogged(false)
		sessionStorage.setItem('lastLocation', '/');
		navigate("/")
		setCurrentModal('HomeModal')
		await signOut({ global: true });
		await OneSignalLogOut()

	}

	return (
		<>
			<div className="PageLogo" onClick={() => setCurrentModal('HomeModal')}>
				<img src={LinkClassLogo} alt="LinkClass" />
			</div>
			<div className="PageModalSelectorAdmin">
				<div className="FullPageSelector BottomLine">
					<div className="FullSubPage">
						<div className="ModalSelectorButtonDiv MainButtonDiv" onClick={() => 
							setCurrentModal('CreateNotice')}
						>
							<img src={CreateNoticeIcon} alt="CreateNoticeIcon" />
							<div className="ModalSelectorSubDiv">
								{FormatSelecterModalText('Nuevo aviso', 'CreateNotice', currentModal)}
							</div>
						</div>
						<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('HomeModal')}>
							<img src={HomeIcon} alt="HomeIcon" />
							<div className="ModalSelectorSubDiv">
								{FormatSelecterModalText('Entrada', 'HomeModal', currentModal)}
							</div>
						</div>
						<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('NoticeList')}>
							<img src={HistoryIcon} alt="HistoryIcon" />
							<div className="ModalSelectorSubDiv">
								{FormatSelecterModalText('Historial', 'NoticeList', currentModal)}
							</div>
						</div>
						<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('GroupList')}>
							<img src={GroupIcon} alt="GroupIcon" />
							<div className="ModalSelectorSubDiv">
								{FormatSelecterModalText('Mis grupos', 'GroupList', currentModal)}
							</div>
						</div>
						<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('HomeReport')}>
							<img src={ReportIcon} alt="ReportIcon" />
							<div className="ModalSelectorSubDiv">
								{FormatSelecterModalText('Informes', 'HomeReport', currentModal)}
							</div>
						</div>
					</div>
				</div>
				<div className="FullSubPage">
					<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('EmergencyTypes')}>
						<img src={HistoryIcon} alt="HistoryIcon" />
						<div className="ModalSelectorSubDiv">
							{FormatSelecterModalText('Situaciones críticas', 'EmergencyTypes', currentModal)}
						</div>
					</div>
					<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('AllGroupList')}>
						<img src={GroupIcon} alt="GroupIcon" />
						<div className="ModalSelectorSubDiv">
							{FormatSelecterModalText('Grupos', 'AllGroupList', currentModal)}
						</div>
					</div>
					<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('StudentsAndUsers')}>
						<img src={ReportIcon} alt="ReportIcon" />
						<div className="ModalSelectorSubDiv">
							{FormatSelecterModalText('Usuarios y Alumnos', 'StudentsAndUsers', currentModal)}
						</div>
					</div>
				</div>
			</div>



			<div className="PageAccountSettings">
				<div className="PageAccountSettingsInternal">
					<div className="ModalSelectorButtonDiv" onClick={() => setCurrentModal('Profile')}>
						<img src={ProfileLogo} alt="ProfileLogo" />
						<div className="ModalSelectorSubDiv">
							{FormatSelecterModalText('Ver mi Perfil', 'Profile', currentModal)}
						</div>
					</div>
					<div className="ModalSelectorButtonDiv" onClick={handleLogOut}>
						<img src={LogOutLogo} alt="LogOutLogo" />
						<div className="ModalSelectorSubDiv">
							{FormatSelecterModalText('Cerrar sesión', '', currentModal)}
						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default SchoolAdminModalSelecter