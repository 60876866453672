import React, { useEffect, useRef, useState } from "react";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { SearchGroupAdminInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces";
import { GroupInterface } from "../../../Interfaces/Entities";
import { FilterGroupsForSearch } from "../../../Helpers/FilterFuntions";
import '../SchoolAdmin.css'



function SearchGroupAdmin({ currentGroups, handleSearchClick }: SearchGroupAdminInterface) {

	const { schoolGroups } = useSchoolAdminModalContext()

	const [currentSearch, setCurrentSearch] = useState<string>('');
	const [filteredGroups, setFilteredGroups] = useState<GroupInterface[]>([]);

	const inputGroupSearchRef = useRef<HTMLInputElement>(null);
	const searchGroupResultsRef = useRef<HTMLDivElement>(null);
	const [popupStyle, setPopupStyle] = useState<{ left: number; top: number; width: number } | null>(null);

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setCurrentSearch(searchValue);
		if (searchValue.trim() === '') {
			setFilteredGroups([]);
			return
		} 
		const filtered = FilterGroupsForSearch(schoolGroups, searchValue, currentGroups)
		setFilteredGroups(filtered);
	};

	useEffect(() => {
		const inputElement = inputGroupSearchRef.current;
		if (inputElement) {
			const rect = inputElement.getBoundingClientRect();
			setPopupStyle({
				left: rect.left + 25,
				top: rect.bottom + window.scrollY - 1,
				width: rect.width - 55
			});
		}
	}, [currentSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchGroupResultsRef.current &&
				!searchGroupResultsRef.current.contains(event.target as Node) &&
				inputGroupSearchRef.current &&
				!inputGroupSearchRef.current.contains(event.target as Node)
			) {
				setFilteredGroups([]);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchGroupResultsRef]);

	const handleInternalClick = (group: GroupInterface) => {
		handleSearchClick(group)
		setFilteredGroups([])
		setCurrentSearch('')
	}

	return (
		<>
			<div className="SearchStudentDiv">
				<input
					ref={inputGroupSearchRef}
					value={currentSearch}
					onChange={handleSearchChange}
					placeholder="Agregar groupo..."
					className="AddGroupSearch"
					autoComplete='searchGroup'
					name="searchGroup"
				/>
			</div>
			{filteredGroups.length > 0 && popupStyle && (
				<div
					className="GroupSearchResults"
					style={{ left: popupStyle.left, top: popupStyle.top, width: popupStyle.width }}
					ref={searchGroupResultsRef}
				>
					<ul className="GroupSearchList">
						{filteredGroups.map((group : GroupInterface, index) => (
							<li key={index}
								className={`GroupSearchItem`}
								onClick={() => handleInternalClick(group)}>
								{group.name ? group.name : group.SK}
							</li>
						))}
					</ul>
				</div>
			)}
		</>
	)
}

export default SearchGroupAdmin