import { ReactNode, useContext, useEffect, useState } from "react";
import { useGeneralContext } from "../GeneralContext/GeneralProvider";
import UserModalContext from "./UserModalContext";
import { DefaultGroup, DefaultNotice } from "../../Interfaces/DefaultEntities";
import { GroupInterface, NoticeInterface, SchoolInterface } from "../../Interfaces/Entities";
import { FetchGetFunction } from "../../Helpers/FetchHelpers";
import { useNavigate } from "react-router-dom";


function UserModalProvider({ children }: { children: ReactNode }) {

	const navigate = useNavigate()

	const { user, groupsOfUserAsGroups, schoolOfUser, setCurrentModal, getInfoOfSchool,
		updateGroupsOfUser, getInfoOfUser, setSchoolOfUser } = useGeneralContext()
	const [savedNotices, setSavedNotices] = useState<NoticeInterface[]>([])
	const [allNotices, setAllNotices] = useState<NoticeInterface[]>([])
	const [unResolvedNotices, setUnResolvedNotices] = useState<NoticeInterface[]>([])
	const [resolvedNotices, setResolvedNotices] = useState<NoticeInterface[]>([])
	const [savedNewsLetters, setSavedNewsLetters] = useState<NoticeInterface[]>([])
	const [isFirstUpdate, setIsFirstUpdate] = useState<boolean>(true)
	const [noticeToSee, setNoticeToSee] = useState<NoticeInterface>(DefaultNotice)
	const [groupToSee, setGroupToSee] = useState<GroupInterface>(DefaultGroup)
	const [isPageVisible, setIsPageVisible] = useState<boolean>(document.visibilityState === 'visible')
	const secondsToWait = process.env.REACT_APP_ENVIOREMENT === 'Local' ? 5 : 5


	const fetchNotices = async (schoolId: string) => {
		try {
			if (!user) {
				return
			}
			await handleNoticeFetch(user.SK, schoolId)
		} catch (error) {
			console.log(error)
		}
	};

	const handleNoticeFetch = async (userId: string, schoolId: string) => {
		const url = `${process.env.REACT_APP_BACKEND_URL}/news?user_id=${userId}&school_id=${schoolId}`;
		const noticeFromBack = await FetchGetFunction(url)
		const editedNotices = noticeFromBack.map((notice: NoticeInterface) => ({
			...notice,
			comments: notice.comments ?? [],
			date: notice.SK.split('.')[0],
			solved: notice.solved ?? false,
		}));
		setAllNotices(editedNotices);
		setIsFirstUpdate(false);
		if (noticeToSee.SK !== DefaultNotice.SK) {
			const currentNoticeToSee = editedNotices.filter((notice: NoticeInterface) => notice.SK === noticeToSee.SK);
			if (currentNoticeToSee) {
				setNoticeToSee(currentNoticeToSee[0]);
			}
		}
	}

	const updateNotices = async () => {
		const schoolId = schoolOfUser ? schoolOfUser.SK : user.school
		await fetchNotices(schoolId);
	};

	const updateNoticesForSpecificSchool = async (school: SchoolInterface, setIsLoading:
		React.Dispatch<React.SetStateAction<boolean>>) => {
		setIsLoading(true)
		await fetchNotices(school.SK);
		setIsLoading(false)
	};


	const updateInternalNotices = (startingNotices: NoticeInterface[]) => {
		const newsLetters = startingNotices.filter((notice: NoticeInterface) => notice.type === 'General')
		const noticesToCheck = startingNotices.filter((notice: NoticeInterface) => notice.type !== 'General')
		const unresolved = noticesToCheck.filter((notice: NoticeInterface) => notice.solved === false)
		const resolved = noticesToCheck.filter((notice: NoticeInterface) => notice.solved === true)
		setSavedNewsLetters(newsLetters)
		setSavedNotices(noticesToCheck)
		setUnResolvedNotices(unresolved)
		setResolvedNotices(resolved)
	}

	const handleGroupSelection = async (groupId: string) => {
		const filteredGroup = groupsOfUserAsGroups.filter((group: GroupInterface) => group.SK === groupId)
		if (filteredGroup.length === 1) {
			setGroupToSee(filteredGroup[0])
			setCurrentModal('SeeGroup')
		}
	}

	const handleLoadingInfo = async (setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
		if (!isFirstUpdate || !user || !user.school) {
			return
		}
		setIsLoading(true)
		getInfoOfSchool(user?.school)
		await fetchNotices(user.school);
		updateGroupsOfUser(user?.school)
		setIsFirstUpdate(false)
		setIsLoading(false)
	}

	const handleAdminEnter = async ( school : SchoolInterface, schoolId : string ) => {
		setSchoolOfUser(school)
		await fetchNotices(schoolId)
	}

	const handleUpdateNotices = async () => {
		if (!user) {
			const userName = sessionStorage.getItem('userName')
			if (!userName) {
				navigate('/')
				return
			}
			const userData = await getInfoOfUser(userName)
			await getInfoOfSchool(userData.school)
			await fetchNotices(userData.school)
			return
		} else {
			if (user.admin === 'SuperAdmin') {
				if (schoolOfUser) {
					await fetchNotices(schoolOfUser.SK)
					return
				} 
			} 
			await fetchNotices(user?.school)
			return
		}
	}

	useEffect(() => {
		if (!isFirstUpdate && savedNotices.length === 0) {
			updateNotices()
		}
		const handleVisibilityChange = () => {
			setIsPageVisible(document.visibilityState === 'visible');
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);

		const intervalId = setInterval(() => {
			setIsPageVisible((prev) => {
				return prev;
			});
			if (isPageVisible) {
				handleUpdateNotices()
			}
		}, secondsToWait * 1000);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
			clearInterval(intervalId);
		};
	}, [schoolOfUser])

	useEffect(() => {
		updateInternalNotices(allNotices)
	}, [allNotices])

	return (
		<UserModalContext.Provider value={{
			savedNotices, setSavedNotices, updateNotices, unResolvedNotices, setUnResolvedNotices, resolvedNotices,
			setResolvedNotices, savedNewsLetters, setSavedNewsLetters, isFirstUpdate, setIsFirstUpdate, allNotices,
			setAllNotices, noticeToSee, setNoticeToSee, groupToSee, setGroupToSee, updateNoticesForSpecificSchool, 
			handleLoadingInfo, handleGroupSelection, handleAdminEnter
		}}>
			{children}
		</UserModalContext.Provider>
	)
}

function useUserModalContext() {
	const context = useContext(UserModalContext);
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider');
	}
	return context
}

export { useUserModalContext }
export default UserModalProvider