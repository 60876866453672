import { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import UploadPopUp from "../../../Components/SchoolAdminModalsComponents/UploadData/UploadPopUp"
import MapGroup from "../../../Components/SchoolAdminModalsComponents/MapData/MapGroup"
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers"
import { FetchPostFunction } from "../../../Helpers/FetchHelpers"
import '../SchoolAdminCSS.css'


function SchoolAdminAllGroupsModal() {

	const { setCurrentModal, schoolOfUser } = useGeneralContext()
	const { schoolGroups } = useSchoolAdminModalContext()
	const [isShowingPopUp, setIsShowingPopUp] = useState<boolean>(false)

	const handleSubmitFile = async (file: File) => {
		if (!schoolOfUser) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/upload-groups?` +
				`school_id=${schoolOfUser.SK}`
			const files = await PrepareFileToSend(file);
			const requestBody = {
				files: files,
			};
			await FetchPostFunction(url, requestBody)
			alert('Grupos correctamente creados')
		} catch (error) {
			alert("Hubo un problema al subir la carga masiva")
		}

	}

	return (
		<div className="ViewAllGroupsMainDiv">
			<div className="AllGroupsButtonsDiv">
				<button type='button' onClick={() => setCurrentModal('CreateGroup')}>
					Crear nuevo
				</button>
				<button type='button' onClick={() => setIsShowingPopUp(true)}>
					Cargar CSV
				</button>
				<a href='/Assets/PlantillaGrupos.csv' download='PlantillaGrupos.csv'>
					<button type='button'>
						Descargar plantilla
					</button>
				</a>
			</div>
			{isShowingPopUp && (
				<UploadPopUp
					onClose={() => setIsShowingPopUp(false)}
					onSubmit={handleSubmitFile}
				/>
			)}
			<table className="AllGroupsTable">
				<thead>
					<tr>
						<th> # </th>
						<th> Nombre </th>
						<th>  </th>
						<th>  </th>
						<th>  </th>
					</tr>
				</thead>
				<tbody>
					{schoolGroups.map((group, index) => (
						<MapGroup
							index={index}
							groupToShow={group}
						/>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default SchoolAdminAllGroupsModal