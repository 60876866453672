import { useState } from 'react'
import { MapUserPrioritiesInterface } from '../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces'
import '../SchoolAdmin.css'
import { useGeneralContext } from '../../../Contexts/GeneralContext/GeneralProvider'
import { RoleInterface } from '../../../Interfaces/Entities'
import { TranslateClassnameForButton } from '../../../Helpers/TranslateHelpers'


function MapUserPriorities({ currentPriorities, updateTemplate }: MapUserPrioritiesInterface) {

	const { schoolOfUser } = useGeneralContext()

	const filteredTemplate = schoolOfUser && schoolOfUser.types	? Object.fromEntries( 
		Object.entries(currentPriorities).filter(([key, value]) => schoolOfUser.types.includes(key))) : {};
	const [internalTemplate, setInternalTemplate] = useState<RoleInterface>(filteredTemplate)
	const [selectedTemplate, setSelectedTemplate] = useState<string>('Usar plantilla')

	const handlePriorityChange = (type: string, priorityCode: string) => {
		let updatedTemplate: RoleInterface;
		if (priorityCode === 'low') {
			const { [type]: _, ...rest } = internalTemplate;
			updatedTemplate = rest;
		} else {
			updatedTemplate = {
				...internalTemplate,
				[type]: priorityCode
			};
		}
		updateInternalTemplate(updatedTemplate);
	}

	const handleTemplateChange = (event) => {
		if (!schoolOfUser) {
			return
		}
		const templateName = event.target.value
		const allTemplates = Object.keys(schoolOfUser.templates)
		if (allTemplates.includes(templateName)) {
			setSelectedTemplate(templateName)
			updateInternalTemplate(schoolOfUser.templates[templateName])
		}
	}

	const updateInternalTemplate = (updatedTemplate: RoleInterface) => {
		setInternalTemplate(updatedTemplate)
		updateTemplate(updatedTemplate)
	}

	return (
		<div className="UserTemplateMainDiv">
			<div className="UserSelectTemplate">
				<select
					id="userTemplate"
					value={selectedTemplate}
					onChange={handleTemplateChange}
					className="SelectTemplateForUser"
				>
					<option value="">Usar plantilla</option>
					{!schoolOfUser ? <></> : Object.keys(schoolOfUser.templates).map((templateName, index) => (
						<option key={index} value={templateName}>
							{templateName}
						</option>
					))}
				</select>
			</div>
			{!schoolOfUser ? <></> : schoolOfUser.types.map((type) => {
				if (type !== 'General') {
					const isTypeSelected = !internalTemplate[type] ? 'low' : internalTemplate[type]
					return (
						<div className="UserTemplatePriorityRow SuperiorLine">
							<h4 className={TranslateClassnameForButton(type, schoolOfUser)}>{type}</h4>
							<div className="PriorityButtonsUser">
								<button
									type='button'
									className={`BasicPrioUserButton ${isTypeSelected === 'low' ?
										'SelectedPrio' : 'UnSelectedPrio'}`}
									onClick={() => handlePriorityChange(type, 'low')}
								>
									Baja
								</button>
								<button
									type='button'
									className={`BasicPrioUserButton ${isTypeSelected === 'mid' ?
										'SelectedPrio' : 'UnSelectedPrio'}`}
									onClick={() => handlePriorityChange(type, 'mid')}
								>
									Media
								</button>
								<button
									type='button'
									className={`BasicPrioUserButton ${isTypeSelected === 'high' ?
										'SelectedPrio' : 'UnSelectedPrio'}`}
									onClick={() => handlePriorityChange(type, 'high')}
								>
									Alta
								</button>
								<button
									type='button'
									className={`BasicPrioUserButton ${isTypeSelected === 'always' ?
										'SelectedPrio' : 'UnSelectedPrio'}`}
									onClick={() => handlePriorityChange(type, 'always')}
								>
									Prioritaria
								</button>
							</div>
						</div>
					)
				}
			})}
		</div>
	)
}

export default MapUserPriorities