import { LandingOnlyChangeModal } from '../../Interfaces/ComponentsInterfaces/LandingPageComponents'
import Funcionamiento from '../../Assets/Funcionamiento.png'
import '../../Modals/LandingModals/LandingModalsCSS.css'

function HowWorksHome( {setCurrentModal} : LandingOnlyChangeModal) {
	return (
		<div className='HowWorksHomeMainDiv'>
			<div className='HowWorksHomeSubDiv'>
				<img src={Funcionamiento} alt='Funcionamiento' />
				<div className='HowWorksHomeTextDiv'>
					<h1>¿Cómo Funciona?</h1>
					<div className='HowWorksHomeDataDiv'>
						<h2>
							Notificación por prioridades
						</h2>
						<h4>
							Clasifica las distintas situaciones críticas y notifica según prioridades, garantizando
							una respuesta eficiente y la confidencialidad de los datos de tus alumnos.
						</h4>
					</div>
					<div className='HowWorksHomeDataDiv'>
						<h2>
							Informes gráficos detallados
						</h2>
						<h4>
							Visualiza la frecuencia y distribución temporal de situaciones críticas mediante gráficos,
							facilitando al comprensión clara y precisa de la realidad de cada estudiante para tomar
							decisiones informadas.
						</h4>
					</div>
					<div className='HowWorksHomeDataDiv'>
						<h2>
							Diseño especializado
						</h2>
						<h4>
							Adaptada específicamente para la comunidad escolar y sus necesidades, permitiéndote
							acceder a información que necesitas cuando y donde quieras.
						</h4>
					</div>
					<button type='button' onClick={() => setCurrentModal('WhatIs')}>
						Ver más
					</button>
				</div>
			</div>
		</div>
	)
}

export default HowWorksHome