import React, { useEffect, useRef, useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { StudentInterface } from "../../../Interfaces/Entities"
import { TranslateClassnameForButton } from "../../../Helpers/TranslateHelpers"
import { FilterStudentsForSearch, FilterUniqueStudents } from "../../../Helpers/FilterFuntions"
import { FormatDate } from "../../../Helpers/DateHelpers"
import '../UserModalCSS.css'
import { FetchPostFunction } from "../../../Helpers/FetchHelpers"


function UserCreateIndividualReportModal() {

	const { schoolOfUser, groupsOfUserAsGroups, user, setCurrentModal } = useGeneralContext()
	const correctSchoolTypes = schoolOfUser ? schoolOfUser.types.filter(item => item !== 'General') : []
	const myStudents = FilterUniqueStudents(groupsOfUserAsGroups)

	const [reportTitle, setReportTitle] = useState<string>('')
	const [reportStartDate, setReportStartDate] = useState<string>('')
	const [reportEndDate, setReportEndDate] = useState<string>('')
	const [reportTypes, setReportTypes] = useState<string[]>([])

	const [currentSearch, setCurrentSearch] = useState<string>('');
	const [filteredStudents, setFilteredStudents] = useState<StudentInterface[]>([]);
	const [selectedStudent, setSelectedStudent] = useState<StudentInterface | undefined>(undefined)

	const inputStudentSearchRef = useRef<HTMLInputElement>(null);
	const searchStudentResultsRef = useRef<HTMLDivElement>(null);
	const [popupStyle, setPopupStyle] = useState<{ left: number; top: number; width: number } | null>(null);

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReportTitle(e.target.value);
	};

	const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReportStartDate(e.target.value)
	};

	const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReportEndDate(e.target.value)
	};

	const handleStateButtonClick = (buttonCode: string) => {
		const updatedTypes = reportTypes.includes(buttonCode)
			? reportTypes.filter(item => item !== buttonCode)
			: [...reportTypes, buttonCode];
		setReportTypes(updatedTypes)
	}

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setCurrentSearch(searchValue);

		if (searchValue.trim() === '') {
			setFilteredStudents([]);
		} else {
			const filtered = FilterStudentsForSearch(myStudents, searchValue, [])
			setFilteredStudents(filtered);
		}
	};

	const handleInternalClick = (student: StudentInterface) => {
		setSelectedStudent(student)
		setFilteredStudents([])
		setCurrentSearch(student.name)
	}

	useEffect(() => {
		const inputElement = inputStudentSearchRef.current;
		if (inputElement) {
			const rect = inputElement.getBoundingClientRect();
			setPopupStyle({
				left: rect.left + 25,
				top: rect.bottom + window.scrollY - 1,
				width: rect.width - 55
			});
		}
	}, [currentSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchStudentResultsRef.current &&
				!searchStudentResultsRef.current.contains(event.target as Node) &&
				inputStudentSearchRef.current &&
				!inputStudentSearchRef.current.contains(event.target as Node)
			) {
				setFilteredStudents([]);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchStudentResultsRef]);

	const handleSubmitReport = async () => {
		if (!schoolOfUser || !user) {
			return
		}
		if (!selectedStudent) {
			alert('Debe elegir un estudiante')
			return
		}
		if(reportTitle.trim() === '') {
			alert('El titulo no puede ser vacio')
			return
		}
		if(reportStartDate.trim() === '') {
			alert('Debe seleccionar una fecha de inicio')
			return
		}
		if(reportEndDate.trim() === '') {
			alert('Debe seleccionar una fecha de termino')
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/student-report/${selectedStudent.rut}`;
			const body = {
				'title': reportTitle,
				'startDate' : FormatDate(new Date(reportStartDate)).split(" ")[0],
				'endDate' : FormatDate(new Date(reportEndDate)).split(" ")[0],
				'school_id': schoolOfUser.SK,
				'email': user.SK
			}
			await FetchPostFunction(url, body)
			alert(`Le llegara el reporte a ${user.SK} dentro de los proximos 15 minutos`)
			setCurrentModal('HomeReport')
		} catch (error) {
			console.log(error)
			alert('Hubo un problema al crear un reporte')
		}
	}

	return (
		<div className="CreateIndividualReportMainDiv">
			<div className="CreateIndividualReportDataField">
				<h3>Título del informe</h3>
				<input
					type="text"
					id='title'
					name='title'
					value={reportTitle}
					onChange={handleTitleChange}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</div>
			<div className="CreateIndividualReportDataField">
				<h3>Nombre del alumno</h3>
				<input
					ref={inputStudentSearchRef}
					value={currentSearch}
					onChange={handleSearchChange}
					autoComplete='studentSearchReport'
					name="searchStudentReport"
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</div>
			{filteredStudents.length > 0 && popupStyle && (
				<div
					className="StudentSearchResults"
					style={{ left: popupStyle.left, top: popupStyle.top, width: popupStyle.width }}
					ref={searchStudentResultsRef}
				>
					<ul className="StudentSearchList">
						{filteredStudents.map((student, index) => (
							<li key={index}
								className={`StudentSearchItem`}
								onClick={() => handleInternalClick(student)}>
								{student.name ? student.name : student.rut}
							</li>
						))}
					</ul>
				</div>
			)}
			<div className="CreateIndividualReportDateField">
				<h3>Fecha del informe</h3>
				<div className="CreateIndividualReportDateMainField">
					<div className="CreateIndividualReportDateSubField">
						<div className="CreateIndividualReportIndividualDate">
							<h4>Desde:</h4>
							<input
								type="date"
								value={reportStartDate}
								onChange={handleStartDateChange}
								max={reportEndDate}
							/>
						</div>
						<div className="CreateIndividualReportIndividualDate">
							<h4>Hasta:</h4>
							<input
								type="date"
								value={reportEndDate}
								onChange={handleEndDateChange}
								min={reportStartDate}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="CreateIndividualReportTypeField">
				<h3>Situaciones crítica</h3>
				<div className="CreateIndividualReportTypeBox">
					{correctSchoolTypes.map((type: string) => (
						<button
							type='button' onClick={() => handleStateButtonClick(type)}
							className={reportTypes.includes(type) ?
								`ReportTypeSelected  ${TranslateClassnameForButton(type, schoolOfUser)}` :
								'ReportTypeUnSelected'
							}
						>
							{type}
						</button>
					))}
				</div>
			</div>

			<button type='button' className="CreateReportButton" onClick={handleSubmitReport}>
				Crear Informe
			</button>
		</div>
	)
}

export default UserCreateIndividualReportModal