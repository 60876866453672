import React, { useEffect, useRef, useState } from "react";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { SearchStudentAdminInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces";
import { StudentInterface } from "../../../Interfaces/Entities";
import CreateStudentPopUp from "../CreateData/CreateStudentPopUp";
import { FilterStudentsForSearch } from "../../../Helpers/FilterFuntions";


function SearchStudentAdmin({ groupStudents, handleSearchClick }: SearchStudentAdminInterface) {

	const { schoolStudents } = useSchoolAdminModalContext()

	const [currentSearch, setCurrentSearch] = useState<string>('');
	const [filteredStudents, setFilteredStudents] = useState<StudentInterface[]>([]);

	const inputStudentSearchRef = useRef<HTMLInputElement>(null);
	const searchStudentResultsRef = useRef<HTMLDivElement>(null);
	const [popupStyle, setPopupStyle] = useState<{ left: number; top: number; width: number } | null>(null);

	const [isShowingCreateStudentPopUp, setIsShowingCreateStudentPopUp] = useState<boolean>(false)

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setCurrentSearch(searchValue);

		if (searchValue.trim() === '') {
			setFilteredStudents([]);
		} else {
			const filtered = FilterStudentsForSearch(schoolStudents, searchValue, groupStudents)
			setFilteredStudents(filtered);
		}
	};

	useEffect(() => {
		const inputElement = inputStudentSearchRef.current;
		if (inputElement) {
			const rect = inputElement.getBoundingClientRect();
			setPopupStyle({
				left: rect.left + 25,
				top: rect.bottom + window.scrollY - 1,
				width: rect.width - 55
			});
		}
	}, [currentSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchStudentResultsRef.current &&
				!searchStudentResultsRef.current.contains(event.target as Node) &&
				inputStudentSearchRef.current &&
				!inputStudentSearchRef.current.contains(event.target as Node)
			) {
				setFilteredStudents([]);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchStudentResultsRef]);

	const handleInternalClick = (student: StudentInterface) => {
		handleSearchClick(student)
		setFilteredStudents([])
		setCurrentSearch('')
	}

	const handleConfirmNewStudent = (studentName: string) => {
		setIsShowingCreateStudentPopUp(false)
		const newStudent : StudentInterface = {
			name: studentName, rut : studentName
		}
		handleInternalClick(newStudent)
	}

	return (
		<>
			<div className="SearchStudentDiv">
				<input
					ref={inputStudentSearchRef}
					value={currentSearch}
					onChange={handleSearchChange}
					placeholder="Agregar estudiante..."
					className="AddStudentSearch"
					autoComplete='studentSearch'
					name="searchStudent"
				/>
				<button type='button' onClick={() => setIsShowingCreateStudentPopUp(true)}>
					Crear nuevo
				</button>
			</div>
			{isShowingCreateStudentPopUp && (
				<CreateStudentPopUp 
					onConfirm={handleConfirmNewStudent}
					onClose={() => setIsShowingCreateStudentPopUp(false)}
				/>
			)}
			{filteredStudents.length > 0 && popupStyle && (
				<div
					className="StudentSearchResults"
					style={{ left: popupStyle.left, top: popupStyle.top, width: popupStyle.width }}
					ref={searchStudentResultsRef}
				>
					<ul className="StudentSearchList">
						{filteredStudents.map((student, index) => (
							<li key={index}
								className={`StudentSearchItem`}
								onClick={() => handleInternalClick(student)}>
								{student.name ? student.name : student.rut}
							</li>
						))}
					</ul>
				</div>
			)}
		</>
	)
}

export default SearchStudentAdmin