import React from "react";
import { SelectLaboralHourInterface } from "../../../Interfaces/ComponentsInterfaces/UserInterfaces";
import '../../SharedComponents/SharedComponents.css'

function SelectLaboralHour({agendaOfDay, setAgendaOfDay, hourCode}: SelectLaboralHourInterface) {

	const validHours = ["08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15",
		"10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15",
		"13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15",
		"16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30"];

	const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setAgendaOfDay({...agendaOfDay, [hourCode]: event.target.value})
	};

	return (
		<div className="SelectLaboralHourMainDiv">
			<label htmlFor="laboral-hour">{hourCode === 'start' ? 'Inicio:' : 'Fin:'}</label>
			<select
				id="laboral-hour"
				name="laboralHour"
				className="AgendaSelect"
				value={agendaOfDay[hourCode]}
				onChange={handleHourChange}
			>
				{validHours.map((hour) => (
					<option key={hour} value={hour}>{hour}</option>
				))}
			</select>
		</div>
	);
}

export default SelectLaboralHour