import { TouchGroupDeletePopUpInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import "../SharedComponents.css"

function TouchGroupDeletePopUp({ groupToDelete, deleteFunction, setIsPopUpVisible }:
	TouchGroupDeletePopUpInterface) {

	const handleConfirmDelete = async () => {
		await deleteFunction(groupToDelete)
	}

	return (
		<div className="popup-container">
			<div className="confirm-popup">
				<h1>¿Seguro que quieres eliminar al grupo {groupToDelete.name}?</h1>
				<div className="confirm-popup-mid-div">
					<button onClick={handleConfirmDelete}>Confirmar</button>
					<button onClick={() => setIsPopUpVisible(false)}>Cancelar</button>
				</div>
			</div>
		</div>
	)
}

export default TouchGroupDeletePopUp