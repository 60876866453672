import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { FilterAlertsInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { GroupOfUserInterface } from "../../../Interfaces/Entities"
import { CreateDateFromNoticeDate } from "../../../Helpers/DateHelpers"
import { TranslateClassnameForButton } from "../../../Helpers/TranslateHelpers"
import '../SharedComponents.css'


function FilterAlerts(
	{ filters, setFilters, setIsFilterActive, filterActivator, setFilterActivator, setIsShowingFilters }:
		FilterAlertsInterface) {

	const Translatedate = (date: Date) => {
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		return `${day}-${month}-${year}`;
	}

	const { schoolOfUser, groupsOfUser } = useGeneralContext()
	const correctSchoolTypes = schoolOfUser ? schoolOfUser.types.filter(item => item !== 'General') : []
	const [startDate, setStartDate] = useState<string>(filters.startDate ? Translatedate(filters.startDate) : '');
	const [endDate, setEndDate] = useState<string>(filters.endDate ? Translatedate(filters.endDate) : '');

	const handleTypeButtonClick = (buttonCode: string) => {
		const updatedFilters = filters.FilterType.includes(buttonCode)
			? filters.FilterType.filter(item => item !== buttonCode)
			: [...filters.FilterType, buttonCode];
		setFilters({
			...filters,
			FilterType: updatedFilters
		});
	}

	const handleGroupButtonClick = (buttonCode: string) => {
		const updatedFilters = filters.FilterGroup.includes(buttonCode)
			? filters.FilterGroup.filter(item => item !== buttonCode)
			: [...filters.FilterGroup, buttonCode];
		setFilters({
			...filters,
			FilterGroup: updatedFilters
		});
	}

	const handleStateButtonClick = (buttonCode: string) => {
		const updatedFilters = filters.FilterState.includes(buttonCode)
			? filters.FilterState.filter(item => item !== buttonCode)
			: [...filters.FilterState, buttonCode];
		setFilters({
			...filters,
			FilterState: updatedFilters
		});
	}

	const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const dateAsDate = CreateDateFromNoticeDate(e.target.value)
		setFilters({
			...filters,
			startDate: dateAsDate
		})
		setStartDate(e.target.value)
	};

	const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const dateAsDate = CreateDateFromNoticeDate(e.target.value)
		setFilters({
			...filters,
			endDate: dateAsDate
		})
		setEndDate(e.target.value)
	};

	const confirmFilter = () => {
		setFilterActivator(filterActivator + 1)
		setIsFilterActive(true)
		setIsShowingFilters(false)
	}

	const cancelFilter = () => {
		setFilters({ FilterGroup: [], FilterType: [], FilterState: [], startDate: undefined, endDate: undefined })
		setIsShowingFilters(false)
	}

	return (
		<div className="MainFilterAlertsDiv">
			<div className="SubMainFilterAlertsDiv">
				<div className="FilterInferiorLine">
					<h3>Situación critica</h3>
					<div className="BasicDiv">
						{correctSchoolTypes.map((type: string) => (
							<button
								type='button' onClick={() => handleTypeButtonClick(type)}
								className={filters.FilterType.includes(type) ?
									`SelectedFilterButton  ${TranslateClassnameForButton(type, schoolOfUser)}` :
									'UnSelectedFilterButton'
								}
							>
								{type}
							</button>
						))}
					</div>
				</div>
				<div className="FilterInferiorLine">
					<h3>Grupo</h3>
					<div className="BasicDiv">
						{groupsOfUser.map((userGroup: GroupOfUserInterface) => (
							<button
								type='button' onClick={() => handleGroupButtonClick(userGroup.group_id)}
								className={filters.FilterGroup.includes(userGroup.group_id) ?
									'SelectedFilterButton SelectedNormalColors' : 'UnSelectedFilterButton'
								}
							>
								{userGroup.label}
							</button>
						))}
					</div>
				</div>
				<div className="FilterInferiorLine FilterMultipleRowObjects">
					<div className="FilterHalfWidth">
						<h3>Estado</h3>
						<div className="BasicHalfDiv StateButton">
							<button type='button' onClick={() => handleStateButtonClick('Resolved')}
								className={filters.FilterState.includes('Resolved') ?
									'SelectedFilterButton SelectedNormalColors' : 'UnSelectedFilterButton'
								}
							>
								Resuelto
							</button>
							<button type='button' onClick={() => handleStateButtonClick('UnResolved')}
								className={filters.FilterState.includes('UnResolved') ?
									'SelectedFilterButton SelectedNormalColors' : 'UnSelectedFilterButton'
								}
							>
								No resuelto
							</button>
						</div>
					</div>
					<div className="FilterHalfWidth">
						<h3>Fechas</h3>
						<div className="BasicHalfDiv LabelDiv">
							<div className="FilterHalfWidth">
								<label>
									<h4>Desde</h4>
									<input
										type="date"
										value={startDate}
										onChange={handleStartDateChange}
										max={endDate} // La fecha de inicio no puede ser posterior a la de fin
									/>
								</label>
							</div>
							<div className="FilterHalfWidth">
								<label>
									<h4>Hasta</h4>
									<input
										type="date"
										value={endDate}
										onChange={handleEndDateChange}
										min={startDate} // La fecha de fin no puede ser anterior a la de inicio
									/>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="FilterFinalButton">
					<button type='button' onClick={() => confirmFilter()}>
						Buscar
					</button>
					<button type='button' onClick={() => cancelFilter()}>
						Cancelar
					</button>
				</div>
			</div>
		</div>
	)
}

export default FilterAlerts