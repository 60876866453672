import { ViewUsersDataInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import { GroupInterface } from "../../../Interfaces/Entities";
import HandleImage from "../HandleData/HandleImage";
import '../SharedComponents.css'

function ViewUsersData({ userToShow, groupsOfUser }: ViewUsersDataInterface) {

	return (
		<div className="ViewUserMainDiv">
			<HandleImage
				userToShow={userToShow}
			/>
			<div className="ViewUserMainDataDiv">
				<div className="ViewUserSubDataDiv">
					<h3>Nombre</h3>
					<h4>{userToShow.name.split(" ")[0]}</h4>
				</div>
				<div className="ViewUserSubDataDiv">
					<h3>Apellidos</h3>
					<h4>{userToShow.name.split(" ")[1]}</h4>
				</div>
				<div className="ViewUserSubDataDiv">
					<h3>Correo</h3>
					<h4 className="ViewUserSpecialh4">{userToShow.SK}</h4>
				</div>
				<div className="ViewUserSubDataDiv">
					<h3>Cargo</h3>
					<h4>{userToShow.position}</h4>
				</div>
			</div>
			<div className="ViewUserGroupsDiv">
				<h3>Mis Grupos</h3>
				<div className="ViewUserGroupsSubDiv">
					{groupsOfUser.map((group: GroupInterface) => (
						<h4>{group.name}</h4>
					))}
				</div>
			</div>
		</div>
	)
}

export default ViewUsersData