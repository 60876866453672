import { useState } from "react";
import { ConfirmationPopUpInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import "../SharedComponents.css"
import { TranslateDeletionType } from "../../../Helpers/TranslateHelpers";

function ConfirmationPopup({ valueToConfirmate, deletionType, setIsPopUpVisible, deleteFunction }:
	ConfirmationPopUpInterface) {

	const [inputValue, setInputValue] = useState<string>('')

	const handleConfirmDelete = async () => {
		if (inputValue.trim() !== valueToConfirmate.trim()) {
			alert('Los valores no coinciden')
			return
		} else {
			await deleteFunction()
		}
	}

	return (
		<div className="popup-container">
			<div className="confirm-popup">
				<h1>¿Seguro que quieres eliminar al {TranslateDeletionType(deletionType)} {valueToConfirmate}?</h1>
				<p>Escribe el nombre del {TranslateDeletionType(deletionType)} para confirmar:</p>
				<input
					type="text"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
				/>
				<div className="confirm-popup-mid-div">
					<button onClick={handleConfirmDelete}>Confirmar</button>
					<button onClick={() => setIsPopUpVisible(false)}>Cancelar</button>
				</div>
			</div>
		</div>
	)
}

export default ConfirmationPopup