import React, { useEffect, useRef, useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import { GroupInterface, GroupOfUserInterface, UserInterface } from "../../../Interfaces/Entities"
import SearchGroupAdmin from "../../../Components/SchoolAdminModalsComponents/SearchData/SearchGroupAdmin"
import { transformGroupOfUserToNormalGroups } from "../../../Helpers/TransformHelpers"
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers"
import { FetchGetFunction, FetchPutFunction } from "../../../Helpers/FetchHelpers"
import defaultImage from '../../../Assets/BasicUserIcon.png'
import TouchGroupDeletePopUp from "../../../Components/SharedComponents/OtherComponents/TouchGroupDeletePopUp"


function SchoolAdminEditUserModal() {
    
	const { schoolUsers, setSchoolUsers, userToEdit } = useSchoolAdminModalContext()
	const { schoolOfUser, setCurrentModal } = useGeneralContext()

	const basicUserName = userToEdit ? (userToEdit.name.includes(' ') ? userToEdit.name.split(' ')[0] : userToEdit.name) : ''
	const basicUserLastName = userToEdit ? (userToEdit.name.includes(' ') ? userToEdit.name.split(' ')[1] : '') : ''
	const basicUserEmail = userToEdit ? userToEdit.email : ''
	const basicUserPosition = userToEdit ? userToEdit.position : ''

	const [userName, setUserName] = useState<string>(basicUserName)
	const [userLastName, setUserLastName] = useState<string>(basicUserLastName)
	const [userEmail, setUserEmail] = useState<string>(basicUserEmail)
	const [userPosition, setUserPosition] = useState<string>(basicUserPosition)
	const [userGroups, setUserGroups] = useState<GroupInterface[]>([])
	const [userGroupsAsSK, setUserGroupsAsSK] = useState<GroupOfUserInterface[]>([])

	const [newGroups, setNewGroups] = useState<string[]>([])
	const [deleteGroups, setDeletedGroups] = useState<string[]>([])


	const [newPicture, setNewPicture] = useState<File | undefined>(undefined)
	const [picturePreview, setPicturePreview] = useState<string | undefined>(undefined);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const [isShowingPopUp, setIsShowingPopUp] = useState<boolean>(false)
	const [groupToDeleteInternal, setGroupToDeleteInternal] = useState<GroupInterface | undefined>(undefined)

	const handleImageClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const objectUrl = URL.createObjectURL(file);
			setNewPicture(file);
			setPicturePreview(objectUrl);
		}
	};

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedName = e.target.value
		setUserName(updatedName)
	}

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedLastName = e.target.value
		setUserLastName(updatedLastName)
	}

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedMail = e.target.value
		setUserEmail(updatedMail)
	}

	const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedPosition = e.target.value
		setUserPosition(updatedPosition)
	}

	const handleSearchClick = (group: GroupInterface) => {
		if (!userGroups.includes(group)) {
			const updatedGroups = [...userGroups, group]
			const groupAsGOU : GroupOfUserInterface = {label : group.name, group_id: group.SK, priorities: {}}
			const updatedSk = [...userGroupsAsSK, groupAsGOU]
			setUserGroups(updatedGroups)
			setUserGroupsAsSK(updatedSk)
			if (deleteGroups.includes(group.SK)) {
				const updatedDeleteGroups = deleteGroups.filter((groupD) => groupD !== group.SK)
				setDeletedGroups(updatedDeleteGroups)
			} else {
				setNewGroups([...newGroups, group.SK])
			}
		}
	}

	const handleDeleteGroupClic = (groupToDelete: GroupInterface) => {
		setGroupToDeleteInternal(groupToDelete)
		setIsShowingPopUp(true)
	}

	const handleGroupDelete = (groupSkToDelete: GroupInterface) => {
		setIsShowingPopUp(false)
		const updatedGroups = userGroups.filter((group: GroupInterface) => (
			group.SK !== groupSkToDelete.SK
		))
		setUserGroups(updatedGroups)
		const updatedGroupOfUser = userGroupsAsSK.filter((groupOfUser : GroupOfUserInterface) => (
			groupOfUser.group_id !== groupSkToDelete.SK
		))
		setUserGroupsAsSK(updatedGroupOfUser)
		if (newGroups.includes(groupSkToDelete.SK)) {
			const updatedNewGroups = newGroups.filter((group) => group !== groupSkToDelete.SK)
			setNewGroups(updatedNewGroups)
		} else {
			setDeletedGroups([...deleteGroups, groupSkToDelete.SK])
		}
	}

	const handleCreateUser = async () => {
		try {
			if (!userToEdit || !schoolOfUser)  {
				return
			}
			if (userEmail.trim() === '' || !userEmail.includes("@")) {
				alert('Mail no valido')
				return
			}
			if (userName.trim() === '' || userLastName.trim() === '') {
				alert('Nombre o apellido no valido')
				return
			}
			const url = `${process.env.REACT_APP_BACKEND_URL}/users`;
			const body = {
				"school_id": schoolOfUser.SK,
				"attrs": {
					"name": `${userName} ${userLastName}`,
					"email": userEmail,
					"position": userPosition
				},
			}
			if (newPicture) {
				body['files'] = await PrepareFileToSend(newPicture)
			}
			await FetchPutFunction(url, body)
			const updatedListOfUsers = schoolUsers.map((user : UserInterface) => user.SK === userToEdit?.SK ? {
				...userToEdit, name : `${userName} ${userLastName}`, email : userEmail, position : userPosition
			} : user )
			setSchoolUsers(updatedListOfUsers)
			const userURL = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${userToEdit.SK}?school_id=${schoolOfUser.SK}`
			for (let index = 0; index < newGroups.length; index++) {
				const newGroupSk = newGroups[index];
				const body = {
					"groups": {[newGroupSk] : {"General" : "mid"}},
					"to_delete": {}
				}
				await FetchPutFunction(userURL, body)
			}
			for (let index = 0; index < deleteGroups.length; index++) {
				const groupToDeleteSk = deleteGroups[index];
				const body = {
					"groups": {},
					"to_delete": {[groupToDeleteSk] : {}}
				}
				await FetchPutFunction(userURL, body)
			}
			alert("Usuario actualizado exitosamente")
		} catch (error) {
			alert("Hubo un problema al crear un usuario")
		}
	}

	const getGroupsOfUserToEdit = async () => {
		if (!userToEdit || !schoolOfUser) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/` +
				`${userToEdit.SK}?school_id=${schoolOfUser.SK}`
			const groupOfUsersSK = await FetchGetFunction(url)
			setUserGroupsAsSK(groupOfUsersSK)
			const groupsAsNormalGroup = transformGroupOfUserToNormalGroups(groupOfUsersSK)
			setUserGroups(groupsAsNormalGroup)
		} catch (error) { }
	}

	useEffect(() => {
		getGroupsOfUserToEdit()
	}, [])

	return (
		<div className="CreateUserMainDiv">
			{isShowingPopUp && groupToDeleteInternal && (
				<TouchGroupDeletePopUp 
					groupToDelete={groupToDeleteInternal}
					setIsPopUpVisible={setIsShowingPopUp}
					deleteFunction={handleGroupDelete}
				/>
			)}
			<div onClick={handleImageClick} className="CreateUserImgDiv">
				{newPicture ? (
					<img src={picturePreview} alt="Logo" />
				) : (
					<img src={defaultImage} alt="Logo" />
				)}
				<input
					type="file"
					ref={fileInputRef}
					style={{ display: 'none' }}
					accept="image/*"
					onChange={handleFileChange}
				/>
			</div>
			<div className="CreateUserTwoInternalDivs">
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Nombre</h4>
					<input
						value={userName}
						onChange={handleNameChange}
					/>
				</div>
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Apellidos</h4>
					<input
						value={userLastName}
						onChange={handleLastNameChange}
					/>
				</div>
			</div>
			<div className="CreateUserTwoInternalDivs">
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Correo electrónico</h4>
					<input
						value={userEmail}
						onChange={handleEmailChange}
					/>
				</div>
				<div className="CreateUserTwoInternalSubDiv">
					<h4>Cargo</h4>
					<input
						value={userPosition}
						onChange={handlePositionChange}
					/>
				</div>
			</div>
			<div className="CreateUserGroupDiv">
				<h4>Grupos del usuario</h4>
				<SearchGroupAdmin
					currentGroups={userGroups}
					handleSearchClick={handleSearchClick}
				/>
				<div className="CreateUserGroupInternalDiv">
					{userGroupsAsSK.length === 0 ? (
						<h4>El usuario no tiene grupos</h4>
					) : (
						<div className="CreateUserMapGroupDiv">
							{userGroupsAsSK.map((group: GroupOfUserInterface) => (
								<button 
									onClick={() => handleDeleteGroupClic(transformGroupOfUserToNormalGroups([group])[0])}
								>
									{group.label}
								</button>
							))}
						</div>
					)}

				</div>
			</div>
			<div className="CreateUserButtonDiv">
				<button type='button' onClick={() => setCurrentModal('StudentsAndUsers')}>
					Cancelar
				</button>
				<button type='button' onClick={handleCreateUser}>
					Guardar cambios
				</button>
			</div>
		</div>
	)
}

export default SchoolAdminEditUserModal