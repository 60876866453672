import { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { ViewIndividualAgendaInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { SchedualInterface } from "../../../Interfaces/Entities"
import SelectLaboralHour from "../SelectData/SelectLaboralHour"
import { TranslateDayOfWeek } from "../../../Helpers/TranslateHelpers"
import { CheckIfAgendaIsValid } from "../../../Helpers/CheckHelpers"
import { FormatDailyAgenda } from "../../../Helpers/FormatHelpers"
import { FetchPutFunction } from "../../../Helpers/FetchHelpers"


function ViewIndividualAgenda({ day, userAgenda, setUserAgenda }: ViewIndividualAgendaInterface) {


	const { user, schoolOfUser } = useGeneralContext()
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [agendaOfDay, setAgendaOfDay] = useState<SchedualInterface>({
		'start': userAgenda[day].split("-")[0],
		'end': userAgenda[day].split("-")[1],
	})

	const saveAgenda = async () => {
		if (!user || !schoolOfUser) {
			return
		}
		if (!CheckIfAgendaIsValid(agendaOfDay)) {
			alert("Tiene una hora no valida")
		} else {
			const updatedAgenda: SchedualInterface = {
				...userAgenda,
				[day]: FormatDailyAgenda(agendaOfDay)
			}
			setUserAgenda(updatedAgenda)
			try {
				const url = `${process.env.REACT_APP_BACKEND_URL}/users/${user.SK}`
				const body = {
					attrs: {
						"schedule": updatedAgenda
					},
					school_id: schoolOfUser.SK
				}
				await FetchPutFunction(url, body)
				alert("Se a actualizado su agenda")
				setIsEditing(false)
			} catch (error) {
				alert("Hubo un problema al cambiar su agenda")
			}
		}
	}

	return (
		<div className="ViewIndividualAgendaMainDiv">
			<div className="ViewIndividualAgendaSuperiorDiv">
				<div className="ViewIndividualAgendaSuperiorLeftDiv">
					<h4>{TranslateDayOfWeek(day)}</h4>
				</div>
				<div className={isEditing? "EvenDistribution" : "OnlyDistribution"}>
					<button type="button" onClick={() => setIsEditing(true)} hidden={isEditing}>
						Editar
					</button>
					<button type="button" onClick={() => setIsEditing(false)} hidden={!isEditing}>
						Cancelar
					</button>
					<button type="button" onClick={saveAgenda} hidden={!isEditing}>
						Confirmar
					</button>
				</div>
			</div>
			<div className="ViewIndividualAgendaInferiorDiv">
				{!isEditing ? (
					<>
						<h4>Inicio: {agendaOfDay["start"]}</h4>
						<h4>Fin: {agendaOfDay["end"]}</h4>
					</>
				) : (
					<>
						<SelectLaboralHour
							agendaOfDay={agendaOfDay}
							setAgendaOfDay={setAgendaOfDay}
							hourCode="start"
						/>
						<SelectLaboralHour
							agendaOfDay={agendaOfDay}
							setAgendaOfDay={setAgendaOfDay}
							hourCode="end"
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default ViewIndividualAgenda