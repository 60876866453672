import BenefitsSection from "../../../Components/LandingPageComponents/BenefitsSection"
import HomeModal from "../../../Components/LandingPageComponents/HomeModal"
import HowWorksHome from "../../../Components/LandingPageComponents/HowWorksHome"
import OurAwards from "../../../Components/LandingPageComponents/OurAwards"
import { LandingOnlyChangeModal } from "../../../Interfaces/ComponentsInterfaces/LandingPageComponents"

function HomeMainModal( {setCurrentModal} : LandingOnlyChangeModal) {
	return (
		<>
			<HomeModal
				setCurrentModal={setCurrentModal}
			/>
			<BenefitsSection
				setCurrentModal={setCurrentModal}
			/>
			<HowWorksHome
				setCurrentModal={setCurrentModal} />
			<OurAwards 
				componentPosition='Home'
			/>
		</>
	)
}

export default HomeMainModal