import { SummarySuperiorSchoolInteface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import defaultLogo from '../../../Assets/BasicSchoolLogo.png'
import '../SharedComponents.css'
import LoadingInfo from "../OtherComponents/LoadingInfo"

function SummarySuperiorSchool({ schoolToSummary }: SummarySuperiorSchoolInteface) {

	return (
		<div className="schoolTopSummaryMainDiv">
			<div className="schoolTopSummaryImgDiv">
				{schoolToSummary ? (
					<img
						src={schoolToSummary.profile_image ? schoolToSummary.profile_image : defaultLogo}
						alt="School Logo"
					/>
				) : (
					<img
						src={defaultLogo}
						alt="School Logo"
					/>
				)}
			</div>
			<div className="schoolTopSummaryInfoDiv">
				{schoolToSummary ? (
					<>
						<h2>{schoolToSummary.name}</h2>
						<h3>{schoolToSummary.SK}</h3>
						<h3>{schoolToSummary.address}</h3>
					</>
				) : (
					<LoadingInfo />
				)}
			</div>
		</div>
	)
}

export default SummarySuperiorSchool