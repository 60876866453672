import { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import ListAllUsers from "../../../Components/SchoolAdminModalsComponents/ListData/ListAllUsers"
import ListAllStudents from "../../../Components/SchoolAdminModalsComponents/ListData/ListAllStudents"
import UploadPopUp from "../../../Components/SchoolAdminModalsComponents/UploadData/UploadPopUp"
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers"
import { FetchPostFunction } from "../../../Helpers/FetchHelpers"
import "../SchoolAdminCSS.css"


function SchoolAdminSAUModal() {

	const [currentlySeen, setCurrentlySeen] = useState<'Users' | 'Students'>('Users')
	const [isShowingPopUp, setIsShowingPopUp] = useState<boolean>(false)
	const { setCurrentModal, schoolOfUser } = useGeneralContext()

	const handleCreateElement = () => {
		if (currentlySeen === 'Users') {
			setCurrentModal('CreateUser')
		} else {
			setCurrentModal('CreateStudent')
		}
	}

	const handleSubmitFile = async (file: File) => {
		if (!schoolOfUser) {
			return
		}
		try {
			const correctUrlCode = currentlySeen === 'Users' ? 'users' : 'groups'
			const url = `${process.env.REACT_APP_BACKEND_URL}/upload-${correctUrlCode}?` +
				`school_id=${schoolOfUser.SK}`
			const files = await PrepareFileToSend(file);
			const requestBody = {
				files: files,
			};
			await FetchPostFunction(url, requestBody)
			const correctCompleteMessage = currentlySeen === 'Users' ? 'Usuarios correctamente creados' :
				'Estudiantes correctamente actualizados'
			alert(correctCompleteMessage)
		} catch (error) {
			alert("Hubo un problema al subir la carga masiva")
		}

	}

	return (
		<>
			<div className="ETButtonDiv">
				<button
					type='button'
					onClick={() => setCurrentlySeen('Users')}
					className={currentlySeen === 'Users' ? 'SelectedButton ETButton' : 'UnSelectedButton ETButton'}
				>
					Usuarios
				</button>
				<button
					type='button'
					onClick={() => setCurrentlySeen('Students')}
					className={currentlySeen === 'Students' ? 'SelectedButton ETButton' : 'UnSelectedButton ETButton'}
				>
					Estudiantes
				</button>
			</div>
			<div className="StudentsAndUserButtonDiv">
				<div className="StudentsAndUserDiv">
					<button type='button' onClick={handleCreateElement}>
						Crear nuevo
					</button>
					<button type='button' onClick={() => setIsShowingPopUp(true)}>
						Cargar CSV
					</button>
					<a href={currentlySeen === 'Users' ? '/Assets/PlantillaUsuarios.csv' : '/Assets/PlantillaGrupos.csv'} 
						download={currentlySeen === 'Users' ? 'PlantillaUsuarios.csv' : 'PlantillaGrupos.csv'}>
						<button type='button'>
							Descargar plantilla
						</button>
					</a>
				</div>
				{isShowingPopUp && (
					<UploadPopUp
						onClose={() => setIsShowingPopUp(false)}
						onSubmit={handleSubmitFile}
					/>
				)}
			</div>
			{currentlySeen === 'Users' ? (
				<ListAllUsers />
			) : (
				<ListAllStudents />
			)}
		</>
	)
}

export default SchoolAdminSAUModal