import { useState } from "react";
import { ViewCreateUserGroupInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces";
import MapUserPriorities from "../MapData/MapUserPriorities";
import { GroupInterface, RoleInterface } from "../../../Interfaces/Entities";
import TrashBinIcon from '../../../Assets/TrashBinIcon.png'
import ConfirmationPopup from "../../SharedComponents/OtherComponents/ConfirmationPopUp";

function ViewCreateUserGroup( {group, currentPriorities, setTemplates, userGroups, setUserGroups} : 
	ViewCreateUserGroupInterface) {

	const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false)
	const [confirmationPopUp, setConfirmationPopUp] = useState<boolean>(false)

	const updateTemplate = async (updatedTemplate: RoleInterface) => {
		const updatedTemplates = {...currentPriorities, [group.SK] : updatedTemplate}
		setTemplates(updatedTemplates)
	};
	
	const handleInternalDeletion = () => {
		const newTemplates = Object.fromEntries(
			Object.entries(currentPriorities).filter(([key]) => key !== group.SK)
		);
		setTemplates(newTemplates);
		const updatedGroups = userGroups.filter((iterationGroup: GroupInterface) => iterationGroup.SK !== group.SK);
		setUserGroups(updatedGroups)
		setConfirmationPopUp(false);
	}

	return (
		<div className="IndividualGroupInternalMainSubDiv">
			<div className={`IndividualGroupInternalDiv`}>
				<h3>{group.name}</h3>
				<button type='button' onClick={() => setIsBoxOpen(!isBoxOpen)}>
					{isBoxOpen ? 'Cerrar' : 'Ver prioridades'}
				</button>
				<button onClick={() => setConfirmationPopUp(true)} className="DeleteCreateUserGroupButton">
					<img src={TrashBinIcon} alt="DeleteIcon"/>
				</button>
			</div>
			{isBoxOpen && (
				<MapUserPriorities 
					currentPriorities={currentPriorities[group.SK] ? currentPriorities[group.SK] : {}}
					updateTemplate={updateTemplate}
				/>
			)}
			{
				confirmationPopUp && (
					<ConfirmationPopup
						valueToConfirmate={group.name}
						deletionType="group"
						deleteFunction={handleInternalDeletion}
						setIsPopUpVisible={setConfirmationPopUp}
					/>
				)
			}
		</div>
		
	)
}

export default ViewCreateUserGroup