import { useEffect, useState } from "react"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { GroupHistoryNoticesInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { NoticeInterface } from "../../../Interfaces/Entities"
import ListGroupOfNotices from "../ListData/ListGroupOfNotices"
import { FilterPreviusNotices, FilterThisWeekNotices, FilterTodaysNotices } from "../../../Helpers/FilterFuntions"
import '../SharedComponents.css'

function GroupHistoryNotices( { filters, isApplingFilters, filterActivator} : GroupHistoryNoticesInterface ) {

	const { savedNotices } = useUserModalContext()
	const [todayNotices, setTodayNotices] = useState<NoticeInterface[]>([])
	const [thisWeekNotices, setThisWeekNotices] = useState<NoticeInterface[]>([])
	const [previusNotices, setPreviusNotices] = useState<NoticeInterface[]>([])

	const distributeNotices = (notices : NoticeInterface[]) => {
		setTodayNotices(FilterTodaysNotices(notices))
		setThisWeekNotices(FilterThisWeekNotices(notices))
		setPreviusNotices(FilterPreviusNotices(notices))
	}

	const resetFilters = () => {
		distributeNotices(savedNotices)
	}

	const applyFilters = () => {
		let filteredNotices = savedNotices
		if (filters.FilterType.length > 0) {
			filteredNotices = savedNotices.filter((notice : NoticeInterface) => filters.FilterType.includes(notice.type))
		}
		if (filters.FilterGroup.length > 0) {
			filteredNotices = filteredNotices.filter(
				(notice : NoticeInterface) => notice.groups.some(group => 
					filters.FilterGroup.includes(group.id))
			)
		}
		if (filters.FilterState.length === 1) {
			const stateCode = filters.FilterState[0]
			if (stateCode === 'Resolved') {
				filteredNotices = filteredNotices.filter((notice : NoticeInterface) => 
					notice.solved
				)
			} else { /* UnResolved Case*/
				filteredNotices = filteredNotices.filter((notice : NoticeInterface) => 
					!notice.solved
				)
			}
		}
		if (filters.startDate) {
			const startDate = filters.startDate
			filteredNotices = filteredNotices.filter((notice : NoticeInterface) => {
				const noticeDate = new Date(notice.date)
				noticeDate.setHours(noticeDate.getHours() + 4)
				return noticeDate >= startDate
			})
		}
		if (filters.endDate) {
			const endDate = filters.endDate
			filteredNotices = filteredNotices.filter((notice : NoticeInterface) => {
				const noticeDate = new Date(notice.date)
				noticeDate.setHours(noticeDate.getHours() + 4)
				return noticeDate <= endDate
			})
		}
		distributeNotices(filteredNotices)
	}

	useEffect(() => {
		if (isApplingFilters) {
			applyFilters()
		} else {
			resetFilters()
		}
	}, [isApplingFilters, filterActivator, savedNotices])

	return (
		<>
			<ListGroupOfNotices
				noticesList={todayNotices}
				listTitle="Alertas de hoy"
				emptyListMessage="No hay avisos de hoy"
			/>
			<ListGroupOfNotices
				noticesList={thisWeekNotices}
				listTitle="Alertas de esta semana"
				emptyListMessage="No hay avisos de esta semana"
			/>
			<ListGroupOfNotices
				noticesList={previusNotices}
				listTitle="Alertas anteriores"
				emptyListMessage="No hay avisos anteriores"
				isLast={true}
			/>
		</>
	)
}

export default GroupHistoryNotices