import { useEffect, useState } from "react"
import { TranslateUserName } from "../../../Helpers/TranslateHelpers"
import { ListGroupUsersInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import "../SharedComponents.css"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"

function ListGroupUsers({ }: ListGroupUsersInterface) {

	const {groupToSee} = useUserModalContext()
	const [groupUsers, setGroupUsers] = useState<string[]>(groupToSee.users_priorities ?
		Object.keys(groupToSee.users_priorities) : [])
	
	useEffect(() => {
		const users = groupToSee.users_priorities ? Object.keys(groupToSee.users_priorities) : []
		setGroupUsers(users)
	}, [groupToSee])

	return (
		<div className="ListGroupUsersMainDiv">
			<h4>Usuarios</h4>
			{groupUsers.length === 0 ? (
				<div className="ListUserEmptyList">
					<h4>Este grupo no tiene usuarios</h4>
				</div>

			) : (
				groupUsers.map((user: string) => (
					<div className="IndividualUserDiv">
						<h3>{TranslateUserName(user)}</h3>
					</div>
				))
			)}
		</div>
	)
}

export default ListGroupUsers