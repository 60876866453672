import { useState } from "react";
import '../../Views/LandingPage/LandingPage.css'

const ContactSection = () => {
	const [formData, setFormData] = useState({
		nombre: "",
		apellido: "",
		nombreColegio: "",
		region: "",
		correo: "",
		telefono: "",
		mensaje: "",
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();
		alert('Mensaje enviado')
	};

	return (
		<section id="contact" className="contact-section">
			<h1>Contáctanos</h1>
			<h3>Si quieres usar LinkClass en tu colegio o tienes alguna duda, envíanos un mensaje.</h3>
			<form className="contact-form" onSubmit={handleSubmit}>
				<div className="form-top-side">
					<div className="form-split-side">
						<div className="input-block">
							<label htmlFor="nombre">Nombre <strong>*</strong></label>
							<input
								type="text"
								id="nombre"
								name="nombre"
								placeholder="Ingrese su nombre"
								value={formData.nombre}
								onChange={handleChange}
							/>
						</div>
						<div className="input-block">
							<label htmlFor="nombreColegio">Nombre del Colegio <strong>*</strong></label>
							<input
								type="text"
								id="nombreColegio"
								name="nombreColegio"
								placeholder="Ingrese el nombre del colegio"
								value={formData.nombreColegio}
								onChange={handleChange}
							/>
						</div>
						<div className="input-block">
							<label htmlFor="correo">Correo <strong>*</strong> </label>
							<input
								type="email"
								id="correo"
								name="correo"
								placeholder="Ingrese su correo"
								value={formData.correo}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="form-split-side">
						<div className="input-block">
							<label htmlFor="apellido">Apellido <strong>*</strong> </label>
							<input
								type="text"
								id="apellido"
								name="apellido"
								placeholder="Ingrese su apellido"
								value={formData.apellido}
								onChange={handleChange}
							/>
						</div>
						<div className="input-block">
							<label htmlFor="region">Región <strong>*</strong> </label>
							<input
								type="text"
								id="region"
								name="region"
								placeholder="Ingrese la región"
								value={formData.region}
								onChange={handleChange}
							/>
						</div>
						<div className="input-block-phone">
							<label htmlFor="telefono">Teléfono</label>
							<input
								type="text"
								id="telefono"
								name="telefono"
								placeholder="Ingrese su teléfono"
								value={formData.telefono}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
				<div className="form-message-div">
					<label htmlFor="mensaje">Mensaje</label>
					<textarea
						id="mensaje"
						name="mensaje"
						placeholder="Ingrese el mensaje"
						value={formData.mensaje}
						onChange={handleChange}
					></textarea>
				</div>
				<div className="form-button-div">
					<button type="submit">Enviar</button>
				</div>
			</form>
			<h2>
				Transforma la comunicación durante situaciones críticas en tu colegio ¡Únete hoy y fortalece tu
				establecimiento!
			</h2>
		</section>
	);
};

export default ContactSection;
