import { GroupOfUserInterface, SchedualInterface } from "../Interfaces/Entities";

export function CheckIfAgendaIsValid(agenda: SchedualInterface): boolean {
	const validHours = {
		"08:00": 0, "08:15": 1, "08:30": 2, "08:45": 3, "09:00": 4, "09:15": 5, "09:30": 6, "09:45": 7,
		"10:00": 8, "10:15": 9, "10:30": 10, "10:45": 11, "11:00": 12, "11:15": 13, "11:30": 14, "11:45": 15,
		"12:00": 16, "12:15": 17, "12:30": 18, "12:45": 19, "13:00": 20, "13:15": 21, "13:30": 22, "13:45": 23,
		"14:00": 24, "14:15": 25, "14:30": 26, "14:45": 27, "15:00": 28, "15:15": 29, "15:30": 30, "15:45": 31,
		"16:00": 32, "16:15": 33, "16:30": 34, "16:45": 35, "17:00": 36, "17:15": 37, "17:30": 38, "17:45": 39,
		"18:00": 40, "18:15": 41, "18:30": 42
	};

	const startHour = agenda['start'] as keyof typeof validHours;
	const endHour = agenda['end'] as keyof typeof validHours;

	if (startHour in validHours && endHour in validHours) {
		return validHours[startHour] < validHours[endHour];
	}
	return false;
}

export function CheckIfNewsLetterIsComplete(currentGroup: string, selectedGroup : (GroupOfUserInterface | undefined), 
	currentTitle: string, currentDescription : string) : boolean {
	if (currentGroup.trim() === '') {
		alert('Debe elegir un grupo')
		return false
	}
	if (!selectedGroup) {
		alert('No hay un grupo seleccionado')
		return false
	}
	if (currentTitle.trim() === '') {
		alert('Debe indicar el titulo')
		return false
	}
	if (currentDescription.trim() === '') {
		alert('Debe indicar el mensaje')
		return false
	}
	return true;
}

export function CheckIfNoticeIsComplete(currentGroup : string, currentType : string, currentDescription: string, 
	selectedGroup: (GroupOfUserInterface | undefined) ): boolean  {
	if (currentGroup.trim() === '') {
		alert('Debe elegir un grupo')
		return false
	}
	if (currentType.trim() === '') {
		alert('Debe elegir un tipo')
		return false
	}
	if (currentDescription.trim() === '') {
		alert('Debe describir el problema')
		return false
	}
	if (!selectedGroup) {
		alert('No hay un grupo seleccionado')
		return false
	}
	return true;
}

export function CheckIfEditPasswordIsComplete(oldPassword : string, newPassword : string, 
	confirmNewPassword : string): boolean {
	return (oldPassword.trim() !== '' && newPassword.trim() !== '' && confirmNewPassword.trim() !== '')
}