import { CommentInterface, GroupInterface, GroupOfUserInterface, NoticeInterface, SchoolInterface, StudentInterface, 
	UserInterface } from "./Entities"


const DefaultUser: UserInterface = {
	name: process.env.REACT_APP_USER_MOCK_NAME || 'defaultName',
	position: process.env.REACT_APP_USER_MOCK_POSITION || 'defaultPosition',
	email: process.env.REACT_APP_USER_MOCK_EMAIL || 'defaultMail',
	SK: process.env.REACT_APP_USER_MOCK_SK || 'defaultSK',
	notification_token: process.env.REACT_APP_USER_MOCK_NOTIFICATION_TOKEN || '',
	off_mode_until: process.env.REACT_APP_USER_MOCK_OFF_MODE_UNTIL || '',
	defaults: [],
	schedule: {}
}

const DefaultGroup: GroupInterface = {
	name: 'DefaultName',
	PK: 'DefaultPk',
	SK: 'DefaultSk',
	students: [],
	users_priorities: {}
}

const DefaultNotice: NoticeInterface = {
	type: 'DefaultType',
	position: DefaultUser.position,
	groups: [],
	description: 'DefaultText',
	location: '',
	user_id: DefaultUser.SK,
	notify: false,
	comments: [],
	images: [],
	SK: 'DefaultSK',
	date: process.env.REACT_APP_USER_MOCK_OFF_MODE_UNTIL || '02-02-2000',
	solved: false
}

const DefaultSchool: SchoolInterface = {
	name: 'DefaultSchool',
	address: 'DefaultAddress',
	SK: process.env.REACT_APP_DEFAULT_SCHOOL_ID || 'DefaultSK',
	types: [],
	templates: {},
	profile_image: undefined
}

const DefaultStudent: StudentInterface = {
	rut: 'DefaultRut',
	name: 'DefaultName'
}

const DefaultPriorities: string[] = [
	'low', 'mid', 'high', 'always'
]

const DefaultGroupOfStudent: GroupOfUserInterface = {
	group_id: '0',
	label: '',
	priorities: {}
}

const DefaultComment: CommentInterface = {
	comment: "",
	user_name: DefaultUser.name,
	user_id: DefaultUser.SK,
}

export {
	DefaultUser, DefaultGroup, DefaultNotice, DefaultSchool, DefaultStudent, DefaultPriorities,
	DefaultGroupOfStudent, DefaultComment
};