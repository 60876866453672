import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { ViewSingularEmergencyInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces"
import { RemoveAccent, TranslateClassnameForButton } from "../../../Helpers/TranslateHelpers"
import TrashBinIcon from '../../../Assets/TrashBinIcon.png'
import EditIcon from '../../../Assets/EditIcon.png'
import ConfirmIcon from '../../../Assets/CheckIcon.png'
import CancelIcon from '../../../Assets/CancelIcon.png'
import '../SchoolAdmin.css'


function ViewSingularEmergency({ emergency, index, emergencies, setCurrentEmergencies }: ViewSingularEmergencyInterface) {

	const { schoolOfUser } = useGeneralContext()
	const [isEditing, setIsEditing] = useState<boolean>(false)
	const [emergencyNewName, setEmergencyNewName] = useState<string>(emergency)
	const cssCode = TranslateClassnameForButton(emergency, schoolOfUser)

	const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setEmergencyNewName(e.target.value)
	}

	const handleConfirmEdit = () => {
		const fixedSchoolEmergencies = emergencies.map(RemoveAccent)
		if (fixedSchoolEmergencies.includes(RemoveAccent(emergencyNewName))) {
			alert("El nombre de esta emergencia no es valido")
			return
		}
		const updatedEmergencies = emergencies.map((mapEmergency : string, mapIndex : number) => 
			mapIndex === index ? emergencyNewName : mapEmergency
		)
		setCurrentEmergencies(updatedEmergencies)
		setIsEditing(false)
	}

	const handleEditCancel = () => {
		setEmergencyNewName(emergency)
		setIsEditing(false)
	}

	const handleDelete = () => {
		const updatedEmergencies = emergencies.filter((filterEmergency : string, filterIndex : number) => (
			filterIndex !== index
		))
		setCurrentEmergencies(updatedEmergencies)
	}

	return (
		<div className='IndividualEmergencyDiv'>
			<div className={`IndividualEmergencyInfo  ${cssCode}`}>
				<h3>{index}</h3>
				{isEditing ? (
					<input
						type='text'
						name='newName'
						value={emergencyNewName}
						onChange={handleNameChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}

					/>
				) : (
					<h4 onClick={() => setIsEditing(true)}>{emergency}</h4>
				)}
			</div>
			<button type='button' hidden={!isEditing} onClick={handleConfirmEdit}>
				<img src={ConfirmIcon} alt="ConfirmIcon"/>
			</button>
			<button type='button' hidden={!isEditing} onClick={handleEditCancel}>
				<img src={CancelIcon} alt="CancelIcon"/>
			</button>
			<button type='button' hidden={isEditing} onClick={() => setIsEditing(true)}>
				<img src={EditIcon} alt="EditButton"/>
			</button>
			<button type='button' onClick={handleDelete}>
				<img src={TrashBinIcon} alt="TrashIconButton"/>
			</button>
		</div>
	)
}

export default ViewSingularEmergency