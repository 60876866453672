import { useState } from 'react';
import { CreateStudentPopUpInterface } from '../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces';
import '../SchoolAdmin.css'

function CreateStudentPopUp({ onConfirm, onClose }: CreateStudentPopUpInterface) {
	const [studentName, setStudentName] = useState<string>('');

	const handleConfirm = () => {
		if (studentName.trim() !== '') {
			onConfirm(studentName);
			setStudentName('');
		}
	};

	const handleCancel = () => {
		setStudentName('');
		onClose();
	};

	return (
		<div className="popup-overlay">
			<div className="popup-content">
				<h4>Nombre del estudiante a crear</h4>
				<input
					type="text"
					value={studentName}
					onChange={(e) => setStudentName(e.target.value)}
					placeholder="Ingrese el nombre del estudiante"
				/>
				<div className="popup-buttons">
					<button type="button" onClick={handleConfirm}>
						Crear estudiante
					</button>
					<button type="button" onClick={handleCancel}>
						Cancelar
					</button>
				</div>
			</div>
		</div>
	);
}

export default CreateStudentPopUp;
