import React from "react"
import { ModalTypes } from "../Interfaces/ContextInterfaces/GeneralContext"
import { TranslateSelecterText } from "./TranslateHelpers"

export function FormatSelecterModalText(text: string, codeSection: string, currentModal: 
	ModalTypes['type']) : JSX.Element | string {
	const translatedModal = TranslateSelecterText(currentModal)
	if (codeSection === translatedModal) {
		return <strong>{text}</strong>
	} else {
		return text
	}
}