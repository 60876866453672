import profile1 from '../../../Assets/OwnerProfile1.png'
import profile2 from '../../../Assets/OwnerProfile2.png'
import profile3 from '../../../Assets/OwnerProfile3.png'

export interface teamMember {
	pictureURL: string,
	name: string,
	job: string,
	info: string
}

export const teamData: teamMember[] = [
	{
		pictureURL: profile1,
		name: 'María Fernanda Freitte',
		job: 'CEO',
		info: "CEO de LinkClass y profesora de la Pontificia Universidad Católica, María Fernanda se destaca por su " +
			"liderazgo en proyectos educativos. En 2020 fue reconocida como una de los 100 jóvenes líderes por su " + 
			"trabajo en el proyecto Canal Educa. Su visión en LinkClass se enfoca en promover la colaboración " +
			"interdisciplinaria dentro de las aulas, siempre poniendo al estudiante en el centro del proceso " +
			"educativo, con un enfoque transformador y adaptativo."
	},
	{
		pictureURL: profile3,
		name: 'Sofía Vicuña',
		job: 'Product Manager',
		info: "Como PM de LinkClass y profesora de la Pontificia Universidad Católica, Sofía se distingue por su " + 
			"capacidad para liderar proyectos interdisciplinarios y fomentar metodologías educativas innovadoras. Su " + 
			"enfoque ha logrado generar cambios significativos en las dinámicas de las aulas. En LinkClass, su " +
			"principal motivación es el apoyo constante a los docentes y mejorar la comunicación efectiva entre los " +
			"usuarios, profesores y estudiantes para un aprendizaje colaborativo."
	},
	{
		pictureURL: profile2,
		name: 'Santiago Laguna',
		job: 'CTO',
		info: "CTO de LinkClass, Santiago es desarrollador de software y está cursando un magister en gestión de " + 
			"portafolio con IA. Con una pasantía en META, adquirió experiencia en proyectos de alto impacto " + 
			"tecnológico. Ha sido clave en el desarrollo de la plataforma móvil y web de LinkClass, creada desde " + 
			"cero. Su motivación radica en el potencial social de la herramienta y su escalabilidad para mejorar la " + 
			"educación a nivel global."
	}
]