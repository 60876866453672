import { LandingOnlyChangeModal } from "../../../Interfaces/ComponentsInterfaces/LandingPageComponents";
import logo from '../../../Assets/LinkClassLogo.png'

function NavBarLogo({setCurrentModal} : LandingOnlyChangeModal) {
	return (
		<div className="navbar-logo" onClick={() => setCurrentModal('Home')}>
			<img src={logo} alt="LinkClass Logo" />
		</div>
	);
}

export default NavBarLogo