import "../../../index.css"

function LoadingInfo() {
	return (
		<div className="LoadingInfo">
			<h1>Cargando.....</h1>
		</div>
	)
}

export default LoadingInfo