import { ListGroupOfNoticesInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import { NoticeInterface } from "../../../Interfaces/Entities";
import ViewSingularNotification from "../ViewData/ViewSingularNotification";
import '../SharedComponents.css'


function ListGroupOfNotices({ noticesList, listTitle, emptyListMessage, isLast=false }: ListGroupOfNoticesInterface) {
	return (
		<div className={`AlertGroupDiv ${!isLast && 'BottomLine'}`}>
			<h4>{listTitle}</h4>
			{noticesList.length === 0 ? (
				<h2>{emptyListMessage}</h2>
			) : (
				noticesList.map((notice: NoticeInterface) => (
					<ViewSingularNotification
						noticeToShow={notice}
						notificationType={notice.solved ? 'Resolved' : 'UnResolved'}
						boxSize="BiggerNotification"
					/>
				))
			)}
		</div>
	)
}

export default ListGroupOfNotices