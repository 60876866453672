import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { ViewSingularNotificationInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { TranslateClassnameForNotice, TranslateNoticeDate } from "../../../Helpers/TranslateHelpers"
import '../SharedComponents.css'
import '../SharedColors.css'


function ViewSingularNotification({ noticeToShow, notificationType, boxSize }: ViewSingularNotificationInterface) {

	const { schoolOfUser, setCurrentModal } = useGeneralContext()
	const { setNoticeToSee } = useUserModalContext()
	const classnameCode = TranslateClassnameForNotice(noticeToShow, notificationType, schoolOfUser)
	const correctUserName = noticeToShow.user_id.split("@")[0]
	const internalBoxSize = `Internal${boxSize}`


	const GetNoticeDatah4 = () => {
		if (notificationType === 'Resolved') {
			return ` en ${noticeToShow.location}`
		} else {
			return ` - ${noticeToShow.location}`
		}
	}

	const GetCorrectInferiorLeftText = () => {
		if (noticeToShow.type === 'General') {
			return noticeToShow.location
		} else {
			return <><strong>{`${noticeToShow.type}`}{noticeToShow.location ? GetNoticeDatah4() : ''}</strong></>
		}
	}

	const handleOnClick = () => {
		setNoticeToSee(noticeToShow)
		setCurrentModal('SeeNotice')
	}

	return (
		<div className={`ViewSingularNotificationMainDiv ${classnameCode} ${boxSize}`} onClick={handleOnClick}>
			<div className="ViewSingularNotificationInternalDiv">
				<div className={`NoticeDescriptionDiv ${internalBoxSize}`}>
					<h1>{noticeToShow.type === 'General' ? noticeToShow.title : noticeToShow.description}</h1>
				</div>
				<div className="NoticeDataDiv">
					<div  className="NoticeDataLeftDiv">
						<h4>{GetCorrectInferiorLeftText()}</h4>
					</div>
					<div  className="NoticeDataRightDiv">
						<h4>Enviado por {correctUserName}, {TranslateNoticeDate(noticeToShow)}</h4>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ViewSingularNotification