import "./LandingFooter.css";

import logo from "./logo.png"
import wsp from "./wsp.png"
import ig from "./ig.png"

const LandingFooter = () => {

	return (
		<section id="footer" className="landing-footer">
			<img src={logo} alt="logo" className="logo" />
			<div className="footer-text">
				<h4>Copyright © 2024 Link Class. All rights reserved</h4>
			</div>
			<div className="socials">
				<a href={process.env.REACT_APP_WHATAPP_LINK} target="_blank" rel="noopener noreferrer">
					<img src={wsp} alt="wsp" className="socials-logo" />
				</a>
				<a href={process.env.REACT_APP_INSTAGRAM_LINK} target="_blank" rel="noopener noreferrer">
					<img src={ig} alt="ig" className="socials-logo" />
				</a>
			</div>
		</section>
	)
}

export default LandingFooter;