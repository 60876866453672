import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import { StudentInterface } from "../../../Interfaces/Entities"
import MapStudent from "../MapData/MapStudent"
import '../SchoolAdmin.css'

function ListAllStudents() {

	const { schoolStudents } = useSchoolAdminModalContext()

	return (
		<table className="AllStudentsTable">
			<thead>
				<tr>
					<th> # </th>
					<th> Nombre estudiante</th>
					<th>  </th> {/* View button column*/}
				</tr>
			</thead>
			<tbody>
				{schoolStudents.map((student : StudentInterface, index: number) => (
					<MapStudent 
						student={student}
						index={index}
					/>
				))}
			</tbody>
		</table>
	)
}

export default ListAllStudents