import { useState } from "react"
import MapEmergencies from "../../../Components/SchoolAdminModalsComponents/MapData/MapEmergencies"
import MapTemplates from "../../../Components/SchoolAdminModalsComponents/MapData/MapTemplates"

function SchoolAdminETModal() {

	const [currentlySeen, setCurrentlySeen] = useState<'Emergencies' | 'Templates'>('Emergencies')

	return (
		<>
			<div className="ETButtonDiv BottomLine">
				<button
					type='button'
					onClick={() => setCurrentlySeen('Emergencies')}
					className={currentlySeen === 'Emergencies' ? 'SelectedButton ETButton' : 'UnSelectedButton ETButton'}
				>
					Situaciones críticas
				</button>
				<button
					type='button'
					onClick={() => setCurrentlySeen('Templates')}
					className={currentlySeen === 'Templates' ? 'SelectedButton ETButton' : 'UnSelectedButton ETButton'}
				>
					Plantillas
				</button>
			</div>
			{currentlySeen === 'Emergencies' ? (
				<MapEmergencies />
			) : (
				<MapTemplates />
			)}
		</>
	)
}

export default SchoolAdminETModal